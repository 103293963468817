import { L, LNotification } from '@lib/abpUtility'
import { notifySuccess } from '@lib/helper'
import { TruckDetailModel, TruckModel } from '@models/truck'
import type { PagedResultDto } from '@services/dto/pagedResultDto'
import truckServices from '@services/truck/truckServices'
import { action, makeObservable, observable } from 'mobx'

function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

class TruckStore {
  @observable isLoading = false
  @observable trucks: PagedResultDto<TruckModel> = { totalCount: 0, items: [] }
  @observable partnerOptions: any[] = []
  @observable truckColorOptions: any[] = []
  @observable editTruck: TruckDetailModel = new TruckDetailModel()
  @observable depositHistories: PagedResultDto<any> = { totalCount: 0, items: [] }

  constructor() {
    makeObservable(this)
  }

  @action async getAll(params) {
    this.isLoading = true
    const result = await truckServices.getAll(params).finally(() => (this.isLoading = false))
    this.trucks = result
  }
  @action async get(id) {
    this.isLoading = true
    const result = await truckServices.get(id).finally(() => (this.isLoading = false))
    this.editTruck = result
    if (result.user) this.partnerOptions.push(result.user)
  }

  @action async create() {
    this.editTruck = new TruckDetailModel()
  }

  @action async getPartnerOptions(params) {
    this.isLoading = true
    const result = await truckServices.getPartnerOptions(params).finally(() => (this.isLoading = false))
    this.partnerOptions = result
  }

  @action async getTruckColorOptions(params?) {
    this.isLoading = true
    const result = await truckServices.getTruckColor(params)
    this.truckColorOptions = result
  }

  @action async createTruck(body) {
    this.isLoading = true
    const data = { ...body }
    data.registerServices = (data.registerServices ?? '')
      .split(',')
      .map((item) => item.trim())
      .join(',')
    data.workingTimes = (data.workingTimes ?? []).join(',')
    const identityCardData = data.truckUserInformation?.identityCard
    const truckCardData = data.truckUserInformation?.truckCard
    const truckData = data.truckUserInformation?.truck
    const safetyCertificationData = data.truckUserInformation?.safetyCertification
    const insuranceData = data.truckUserInformation?.insurance
    delete data.truckUserInformation['identityCard']
    delete data.truckUserInformation['truckCard']
    delete data.truckUserInformation['truck']
    delete data.truckUserInformation['safetyCertification']
    delete data.truckUserInformation['insurance']
    const result = await truckServices.createTruck(data)

    await Promise.all([
      truckServices.uploadPhoto(identityCardData, 'IdentityCard', result.uniqueId),
      truckServices.uploadPhoto(truckCardData, 'TruckCard', result.uniqueId),
      truckServices.uploadPhoto(truckData, 'Truck', result.uniqueId),
      truckServices.uploadPhoto(safetyCertificationData, 'SafetyCertification', result.uniqueId),
      truckServices.uploadPhoto(insuranceData, 'Insurance', result.uniqueId)
    ])
    this.editTruck = result
    this.isLoading = false
    notifySuccess(LNotification('SUCCESS'), L('CREATE_SUCCESSFULLY'))
  }

  @action async updateTruck(body) {
    this.isLoading = true
    const data = { ...body }
    data.workingTimes = data.workingTimes.join(',')
    this.editTruck = await truckServices.updateTruck(data).finally(() => {
      this.isLoading = false
    })
  }

  @action async getDocument(uniqueId) {
    this.isLoading = true
    const photoList = await truckServices.getDocument(uniqueId).finally(() => (this.isLoading = false))
    if (this.editTruck) {
      const identityCard = photoList
        .filter((photo) => photo.moduleName === 'IDENTITYCART')
        .map((photo) => {
          return {
            ...photo,
            uid: photo.guid,
            name: photo.fileName,
            url: photo.fileUrl
          }
        })
      const insurance = photoList
        .filter((photo) => photo.moduleName === 'INSURANCE')
        .map((photo) => {
          return {
            ...photo,
            uid: photo.guid,
            name: photo.fileName,
            url: photo.fileUrl
          }
        })
      const safetyCertification = photoList
        .filter((photo) => photo.moduleName === 'SAFETYCERTIFICATION')
        .map((photo) => {
          return {
            ...photo,
            uid: photo.guid,
            name: photo.fileName,
            url: photo.fileUrl
          }
        })
      const truck = photoList
        .filter((photo) => photo.moduleName === 'TRUCK')
        .map((photo) => {
          return {
            ...photo,
            uid: photo.guid,
            name: photo.fileName,
            url: photo.fileUrl
          }
        })
      const truckCard = photoList
        .filter((photo) => photo.moduleName === 'TRUCKCART')
        .map((photo) => {
          return {
            ...photo,
            uid: photo.guid,
            name: photo.fileName,
            url: photo.fileUrl
          }
        })

      this.editTruck = {
        ...this.editTruck,
        truckUserInformation: {
          identityCard,
          insurance,
          safetyCertification,
          truck,
          truckCard
        }
      }
    }

    return photoList
  }

  @action async deactivated(id) {
    this.isLoading = true
    const result = await truckServices.deactivated(id)
    notifySuccess(LNotification('SUCCESS'), LNotification('DEACTIVATED_SUCCESSFULLY'))
    await this.getAll({}).finally(() => (this.isLoading = false))
    return result
  }

  @action async active(id, isActive) {
    this.isLoading = true
    const result = await truckServices.active(id, isActive)
    isActive
      ? notifySuccess(LNotification('SUCCESS'), LNotification('ACTIVATED_SUCCESSFULLY'))
      : notifySuccess(LNotification('SUCCESS'), LNotification('DEACTIVATED_SUCCESSFULLY'))

    await this.getAll({}).finally(() => (this.isLoading = false))
    return result
  }

  @action
  async uploadTruckImage(module, files) {
    if (!module || !files?.length) {
      return
    }

    await truckServices.uploadPhoto(files, capitalize(module), this.editTruck.uniqueId)
  }

  @action
  async setTruckImage(module, files) {
    if (!this.editTruck.truckUserInformation) {
      this.editTruck.truckUserInformation = {}
    }
    this.editTruck.truckUserInformation[module] = files
  }
}

export default TruckStore
