import { appStatusColors, AppStatus } from '@lib/appconst'
import { formatDateTimeSecond } from '@lib/helper'
import { Status } from '@models/global'
import { UserModel } from '@models/user/IUserModel'
import dayjs from 'src/lib/dayjs'
const { removalStatus } = AppStatus

export class RemovalModel {
  cheatLocations?: any
  commissionAmount?: number
  commissionRate?: number

  customerRating?: number
  customerUser?: UserModel
  estQuantity?: number
  files?: any
  id?: number
  isActive?: boolean
  isCompletedDistribution?: boolean
  isWarningCheat?: boolean
  cheatStatus?: Status
  linkTruckUserInformation?: string
  linkedUniqueId?: string
  location?: any
  note?: string
  partnerRating?: number
  paymentMethod?: number
  product?: any
  promotionUsed?: any
  removalRequestContact?: number
  removalRequestContactId?: number
  removalRequestDetails?: any
  status?: Status
  statusId?: number
  totalAmount?: number
  truckType?: any
  truckUserInformation?: any
  uniqueId?: string
  warningLevel?: number

  creationTime?: Date
  creatorUser?: UserModel
  lastModificationTime?: Date
  lastModifierUser?: UserModel
  allowPreviewLastTrip?: boolean

  constructor() {
    this.id = undefined
    this.isActive = true
    this.allowPreviewLastTrip = false
  }

  public static assign(obj) {
    if (!obj) return undefined

    const newObj = Object.assign(new RemovalModel(), obj)
    newObj.creationTime = obj.creationTime ? dayjs(obj.creationTime) : undefined
    newObj.lastModificationTime = obj.lastModificationTime ? dayjs(obj.lastModificationTime) : undefined
    newObj.cheatStatus = obj.isWarningCheat
      ? new Status('CHEAT_DETECTED', appStatusColors.error)
      : new Status('CHEAT_NORMAL', appStatusColors.success)
    const isUpdatedWithin5Minutes = dayjs().diff(newObj.lastModificationTime, 'minute') < 5
    newObj.allowPreviewLastTrip =
      isUpdatedWithin5Minutes &&
      (obj.status?.code === removalStatus.processing || obj.status?.code === removalStatus.waitingForComplete)
    return newObj
  }

  public static assigns<T>(objs) {
    const results: RemovalModel[] = []
    objs.forEach((item) => results.push(this.assign(item)))
    return results
  }
}

export class RemovalDetailModel {
  cheatLocations?: any
  commissionAmount?: number
  commissionRate?: number

  customerRating?: number
  customerUserId = 0
  customerUser?: UserModel
  estQuantity?: number
  files?: any
  id?: number
  isActive?: boolean
  isCompletedDistribution?: boolean
  isWarningCheat?: boolean
  cheatStatus?: Status
  linkTruckUserInformation?: string
  linkedUniqueId?: string
  location?: any
  note?: string
  partnerRating?: number
  paymentMethod?: number
  product?: any
  promotionUsed?: any
  removalRequestContact?: number
  removalRequestContactId?: number
  removalRequestDetails?: RemovalTripDetailModel[]
  status?: Status
  statusId?: number
  totalAmount?: number
  truckTypeId = 0
  truckType?: any
  truckUserInformationId = 0
  truckUserInformation?: any
  uniqueId?: string
  warningLevel?: number

  creationTime?: Date
  creatorUser?: UserModel
  lastModificationTime?: Date
  lastModifierUser?: UserModel

  constructor() {
    this.id = undefined
    this.isActive = true
  }

  public static assign(obj) {
    if (!obj) return undefined

    const newObj = Object.assign(new RemovalDetailModel(), obj)
    newObj.customerUserId = obj.customerUser.id
    newObj.truckTypeId = obj.truckType.id
    newObj.truckUserInformationId = obj.truckUserInformation?.id
    newObj.removalRequestDetails = obj.removalRequestDetails
      ? RemovalTripDetailModel.assigns(obj.removalRequestDetails)
      : []
    newObj.creationTime = obj.creationTime ? dayjs(obj.creationTime) : undefined
    newObj.lastModificationTime = obj.lastModificationTime ? dayjs(obj.lastModificationTime) : undefined

    return newObj
  }

  public static assigns<T>(objs) {
    const results: RemovalDetailModel[] = []
    objs.forEach((item) => results.push(this.assign(item)))
    return results
  }
}

export class RemovalTripDetailModel {
  id: number
  uniqueId?: string
  amount: number
  cancelReason: string
  completeLocation: any
  fileAfter: FileImageModel[] = []
  fileBefore: FileImageModel[] = []
  orderNumber: number
  remainEst: number
  serviceFeeAmount: number
  serviceFees: any
  status: any
  statusId: number
  totalAmount: number

  creationTime?: Date
  creatorUser?: UserModel
  lastModificationTime?: Date
  lastModifierUser?: UserModel

  constructor() {
    this.id = 0
    this.amount = 0
    this.cancelReason = ''
    this.orderNumber = 0
    this.remainEst = 0
    this.serviceFeeAmount = 0
    this.serviceFees = []
    this.statusId = 0
    this.totalAmount = 0
  }

  public static assign(obj) {
    if (!obj) return undefined

    const newObj = Object.assign(new RemovalTripDetailModel(), obj)
    newObj.fileAfter = obj.fileAfter ? FileImageModel.assigns(obj.fileAfter) : []
    newObj.fileBefore = obj.fileBefore ? FileImageModel.assigns(obj.fileBefore) : []
    return newObj
  }

  public static assigns<T>(objs) {
    const results: RemovalTripDetailModel[] = []
    objs.forEach((item) => results.push(this.assign(item)))
    return results
  }
}

export class FileImageModel {
  description = ''
  fileName = ''
  fileUrl = ''
  guid = ''
  id = 0
  mimeType = ''
  moduleName = ''
  originalFileName = ''
  size = 0

  public static assign(obj) {
    if (!obj) return undefined

    const newObj = Object.assign(new FileImageModel(), obj)
    newObj.creationTime = obj.creationTime ? dayjs(obj.creationTime) : undefined
    newObj.description = `${formatDateTimeSecond(obj.creationTime)} - ${obj.description}`
    return newObj
  }

  public static assigns<T>(objs) {
    const results: FileImageModel[] = []
    objs.forEach((item) => results.push(this.assign(item)))
    return results
  }
}
