import { processFromToParams } from '@lib/helper'
import { downloadFile } from '@lib/helperFile'
import { OptionModel } from '@models/global'
import { RemovalDetailModel, RemovalModel } from '@models/removal/removalModel'
import { RemovalSettingModel } from '@models/removal/removalSettingModel'

import type { PagedResultDto } from '@services/dto/pagedResultDto'
import http from '@services/httpService'
class RemovalService {
  public async getAll(filters: any): Promise<PagedResultDto<RemovalModel>> {
    const params = processFromToParams(filters)
    const res = await http.get('api/services/app/RemovalRequests/GetAll', { params })
    const result = res.data.result
    result.items = RemovalModel.assigns(result.items)
    return result
  }
  public async get(id: number) {
    const res = await http.get('api/services/app/RemovalRequests/Get', { params: { id } })
    const result = RemovalDetailModel.assign(res.data.result)
    return result
  }

  public async getRequestSetting() {
    const res = await http.get('api/services/app/RemovalRequests/GetRequestSetting')
    return RemovalSettingModel.assign(res.data.result)
  }

  public async getRequestSettingV2(params) {
    const res = await http.post('api/services/app/RequestHelpers/GetRequestSettingV2', params)
    const result = res.data.result
    return result
  }

  public async getCustomerOptions(params) {
    const res = await http.get('api/services/app/Customers/GetAll', { params })
    const result = res.data.result
    return result
  }

  public async getPartnerOptions(params) {
    const res = await http.get('api/services/app/RequestHelpers/GetSuggestTrucks', { params })
    const result = res.data.result
    return result
  }

  public async createRemoval(body) {
    const res = await http.post('api/services/app/RemovalRequests/Create', body)
    const result = res.data.result
    return result
  }

  public async updateStatusRemoval(body) {
    const res = await http.put('api/services/app/RemovalRequests/UpdateStatus', body)

    const result = res.data.result
    return result
  }
  public async getDocument(uniqueId) {
    const res = await http.get('/api/services/app/Documents/GetDocuments', { params: { uniqueId } })
    const result = res.data.result
    return result
  }

  public async deactivate(id) {
    await http.delete('api/services/app/RemovalRequests/Delete', { params: { id } })
  }

  public async getCustomerPromotion(userId) {
    const res = await http.get('api/services/app/RequestHelpers/GetPromotionsOfUser', { params: { userId } })
    const result = OptionModel.assigns(
      (res.data.result || []).map((item) => ({ ...item, value: item.code, name: item.name }))
    )
    return result
  }

  public async checkPrice(body: any) {
    const res = await http.post('api/services/app/Transportation/CheckTransportationCost', body)
    return res.data.result
  }

  public async checkWallet(userId) {
    const res = await http.get('api/services/app/RequestHelpers/GetUserBalance', { params: { userId } })
    return res.data.result
  }
  public async getRemovalStatus(id) {
    const res = await http.get('api/services/app/RemovalRequests/GetNextStatus', { params: { id } })
    return res.data.result
  }
  public async getListStatus() {
    const res = await http.get('/api/services/app/RemovalRequests/GetStatus')
    return res.data.result
  }

  public async getCancelOptionsRequest() {
    const res = await http.get('api/services/app/RequestHelpers/GetCancelReasons')
    return res.data.result
  }

  public async getCancelOptionsDetail() {
    const res = await http.get('api/services/app/RequestHelpers/GetCancelReason4Details')
    return res.data.result
  }

  public async getDistributionHistory(params) {
    const res = await http.get('api/services/app/RemovalRequests/GetAllDistributionHistory', { params })
    return res.data.result
  }

  public async exportRemovalRequest(filters: any): Promise<any> {
    const params = processFromToParams(filters)

    const res = await http.get('/api/Export/ExportRemovalRequests', { params, responseType: 'blob' })
    downloadFile(res.data, 'remeval-request-export.xlsx')
  }
}

export default new RemovalService()
