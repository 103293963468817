import { action, makeObservable, observable } from 'mobx'

import AppConsts from './../lib/appconst'
import LoginModel from '../models/account/Login/loginModel'
import tokenAuthService from '../services/tokenAuth/tokenAuthService'

declare let abp: any

class AuthenticationStore {
  @observable isLoading!: boolean
  @observable loginModel: LoginModel = new LoginModel()

  constructor() {
    makeObservable(this)
  }

  get isAuthenticated(): boolean {
    if (!abp.session.userId) return false

    return true
  }

  @action
  public async login(model: LoginModel) {
    this.isLoading = true
    const result = await tokenAuthService
      .authenticate({
        userNameOrEmailAddress: model.userNameOrEmailAddress,
        password: model.password,
        rememberClient: model.rememberMe
      })
      .finally(() => (this.isLoading = false))

    const tokenExpireDate = model.rememberMe
      ? new Date(new Date().getTime() + 1000 * result.expireInSeconds)
      : undefined
    abp.auth.setToken(result.accessToken, tokenExpireDate)
    abp.utils.setCookieValue(
      AppConsts.authorization.encrptedAuthTokenName,
      result.encryptedAccessToken,
      tokenExpireDate,
      abp.appPath,
      undefined,
      { Secure: true }
    )
  }

  @action async logout() {
    abp.utils.deleteCookie(AppConsts.authorization.encrptedAuthTokenName, abp.appPath)
    abp.utils.deleteCookie(AppConsts.authorization.projectId, abp.appPath)

    localStorage.clear()
    sessionStorage.clear()
    abp.auth.clearToken()
  }
}
export default AuthenticationStore
