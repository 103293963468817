import http from '@services/httpService'
import { CashAdvanceModel, ICashAdvance } from '@models/finance/cashAdvanceModel'
import { notifySuccess } from '@lib/helper'
import { LNotification } from '@lib/abpUtility'
import { OptionModel } from '@models/global'
import { TransactionModel } from '@models/finance/transactionModel'
import { downloadFile } from '@lib/helperFile'
import dayjs from 'src/lib/dayjs'

class CashAdvanceService {
  async createDeposit(body) {
    const result = await http.post('/api/services/app/CashAdvance/AddDeposit', body)
    notifySuccess(LNotification('SUCCESS'), LNotification('SAVING_SUCCESSFULLY'))
    return result.data.result
  }

  async update(body) {
    const result = await http.put('api/services/app/CashAdvance/Update', body)
    notifySuccess(LNotification('SUCCESS'), LNotification('SAVING_SUCCESSFULLY'))
    return result.data.result
  }

  async getPaymentChannels() {
    const res = await http.get('api/services/app/CashAdvance/GetChannels')
    return OptionModel.assigns(res.data.result)
  }

  async activateOrDeactivate(id, isActive) {
    const result = await http.post('api/services/app/CashAdvance/Active', { id }, { params: { isActive } })
    notifySuccess(LNotification('SUCCESS'), LNotification('SAVING_SUCCESSFULLY'))
    return result.data.result
  }

  async delete(id) {
    const result = await http.delete('api/services/app/CashAdvance/Delete', { params: { id } })
    return result.data
  }

  async get(userId): Promise<any> {
    const result = await http.get('api/services/app/User/GetUserBalanceAmount', { params: { userId } })
    return CashAdvanceModel.assign(result.data.result || {})
  }

  async checkExist(code): Promise<any> {
    const result = await http.post('api/services/app/CashAdvance/CheckExistCode', null, { params: { code } })
    return result.data.result
  }

  async filter(filters): Promise<any> {
    const params = this.proccessParams(filters)

    const res = await http.get('api/services/app/CashAdvance/GetAll', { params })
    const result = res.data.result
    result.items = CashAdvanceModel.assigns(result.items)
    return result
  }

  async getAll(params): Promise<ICashAdvance[]> {
    const res = await http.get('api/services/app/CashAdvance/GetLists', { params })
    return Promise.resolve(res.data.result)
  }

  // Cash advance detail
  async filterCashAdvanceTransactions(filters): Promise<any> {
    const params = this.proccessParams(filters)

    const res = await http.get('api/services/app/CashAdvance/GetCashAdvanceReceipts', { params: params })
    const result = res.data.result
    result.items = TransactionModel.assigns(result.items)
    return result
  }

  public async exportCashAdvance(filters: any): Promise<any> {
    const params = this.proccessParams(filters)

    const res = await http.get('api/Export/ExportCashAdvance', { params, responseType: 'blob' })
    downloadFile(res.data, 'cash-advance-export.xlsx')
  }

  public async exportCashAdvanceDetailTransactions(filters: any): Promise<any> {
    const params = this.proccessParams(filters)

    const res = await http.get('api/Export/ExportCashAdvanceReceipts', { params, responseType: 'blob' })
    downloadFile(res.data, 'cash-advance-detail-transactions-export.xlsx')
  }

  private proccessParams(params) {
    const filters = { ...(params || {}) }

    const [fromDate, toDate] = params.dateFromTo || []
    filters.fromDate = fromDate ? dayjs(fromDate).startOf('day').toJSON() : null
    filters.toDate = toDate ? dayjs(toDate).endOf('day').toJSON() : null
    delete filters.dateFromTo

    return filters
  }
}

export default new CashAdvanceService()
