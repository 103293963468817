import { L } from '@lib/abpUtility'
import { v4 as uuid } from 'uuid'
import AppConsts, { AppStatus } from '@lib/appconst'

export class PartnerAnalyticModel {
  guid: string = uuid()
  acceptedRequestRate?: number
  displayName = ''
  emailAddress = ''
  isAllowAutoReceiveRequest = false
  isAllowReceiveRequest = false
  note = ''
  numberPlates = ''
  parkingPlaces?: string
  periodicPoint?: number
  profilePictureUrl = ''
  readyStatus = AppConsts.readyStatus.notReady
  registerServices = ''
  totalBadgeAward = 0
  totalBlocked = 0
  totalCompletedRequest = 0
  totalCompletedRequestDetail = 0
  totalRate = 0
  totalReturnPoint = 0
  totalReturnRate = 0
  totalTimeActivity = 0
  totalTimeOpenApp = 0
  totalTimeWait = 0
  totalWarning = 0
  truckTypeName = ''
  truckUserInformationId = 0
  userId = 0
  userName = ''
  workingTimes = ''
  creationTime = ''

  public static assign(obj) {
    if (!obj) return undefined

    const newObj = Object.assign(new PartnerAnalyticModel(), obj)
    const selectedWorkingTimes = (obj.workingTimes || '').split(',')
    newObj.workingTimes = AppConsts.workingTimes
      .filter((item) => selectedWorkingTimes.includes(item.value))
      .map((item) => L(item.name))
      .join(', ')
    // MDT turn on: isAllowReceiveRequest = true
    // MDT turn off: isAllowReceiveRequest = false
    // UserStatus = 0  => User không đủ điều kiện (tiền và 1 số trạng thái khác)
    const userReady =
      obj.isAllowReceiveRequest && obj.isConfirmed && obj.isUserBlocked == false && obj.userStatusId == 1
    const { readyStatus } = AppConsts
    const { removeStatusId } = AppStatus

    if (userReady && obj.requestStatusId == null) {
      newObj.readyStatus = readyStatus.ready
    } else if (userReady && obj.requestStatusId > 0) {
      newObj.readyStatus =
        obj.requestStatusId == removeStatusId.received || obj.requestStatusId == removeStatusId.onTheWay
          ? readyStatus.received
          : readyStatus.inprogress
    } else {
      newObj.readyStatus = AppConsts.readyStatus.notReady
    }

    return newObj
  }

  public static assigns<T>(objs) {
    const results: any[] = []
    objs.forEach((item) => results.push(this.assign(item)))
    return results
  }
}
