import type { PagedResultDto } from '../dto/pagedResultDto'
import http from '../httpService'
import { TransportationCostModel } from '../../models/master-data/transportationCostModel'
import { notifyError, notifySuccess } from '@lib/helper'
import { L, LNotification } from '@lib/abpUtility'

class HolidayService {
  public async create(body: any) {
    const res = await http.post('api/services/app/Holiday/Create', body)
    notifySuccess(LNotification('SUCCESS'), LNotification(L('SAVE_SUCCESSFULLY')))
    return TransportationCostModel.assign(res.data.result)
  }

  public async filter(params: any): Promise<PagedResultDto<any>> {
    const res = await http.get('api/services/app/Holiday/GetAll', {
      params
    })
    const result = res.data.result
    return result
  }

  public async update(body: any) {
    const res = await http.put('api/services/app/Holiday/Update', body)
    notifySuccess(LNotification('SUCCESS'), LNotification(L('SAVE_SUCCESSFULLY')))
    return TransportationCostModel.assign(res.data.result)
  }

  public async getById(id): Promise<any> {
    if (!id) {
      notifyError(L('ERROR'), L('ENTITY_NOT_FOUND'))
    }
    const result = await http.get('/api/services/app/Transportation/Get', {
      params: { id }
    })
    return TransportationCostModel.assign(result.data.result)
  }

  public async activateOrDeactivate(id: number, isActive) {
    const response = await http.post('api/services/app/Holiday/Active', { id, isActive })
    notifySuccess(LNotification('SUCCESS'), LNotification('SAVING_SUCCESSFULLY'))
    return response.data
  }

  public async delete(id: number, isActive) {
    const response = await http.delete('api/services/app/Holiday/Delete', {
      params: { isActive }
    })
    return response.data
  }
}

export default new HolidayService()
