import type { PagedResultDto } from '@services/dto/pagedResultDto'
import * as announcementModel from '@models/communication/Announcement/announcementModel'
import { action, makeObservable, observable } from 'mobx'
import announcementService from '@services/communication/announcementService'
import { OptionModel } from '@models/global'
import fileService from '@services/common/fileService'
import { moduleFile } from '@lib/appconst'

class AnnouncementStore {
  @observable pagedData!: PagedResultDto<announcementModel.IAnnouncement>
  @observable isLoading!: boolean
  @observable announcements!: announcementModel.IAnnouncement[]
  @observable editAnnouncement!: announcementModel.IAnnouncement
  @observable announcementTypes!: OptionModel[]

  constructor() {
    this.pagedData = {
      items: [],
      totalCount: 0
    }
    this.announcementTypes = []
    makeObservable(this)
  }

  @action
  async create(body, files?) {
    this.isLoading = true
    this.editAnnouncement = await announcementService.create(body).finally(async () => {
      this.isLoading = !!(files && files.length)
    })
    const { uniqueId } = this.editAnnouncement
    if (files?.length && uniqueId) {
      await fileService.upload(moduleFile.announcement, uniqueId, files).finally(() => {
        this.isLoading = false
      })
    }
  }

  @action
  async update(body, files?) {
    this.isLoading = true
    await announcementService.update(body).finally(async () => {
      this.isLoading = !!(files && files.length)
    })
    const { uniqueId } = this.editAnnouncement
    if (files?.length && uniqueId) {
      await fileService.upload(moduleFile.announcement, uniqueId, files).finally(() => {
        this.isLoading = false
      })
    }
  }

  @action
  async activateOrDeactivate(id, isActive) {
    await announcementService.activateOrDeactivate(id, isActive)
  }

  @action
  async delete(id) {
    await announcementService.delete(id)
    this.pagedData.items = this.pagedData.items.filter((x) => x.id !== id)
  }

  @action
  async get(id) {
    const result = await announcementService.get(id)
    this.editAnnouncement = result
  }

  @action
  async createAnnouncement() {
    this.editAnnouncement = new announcementModel.AnnouncementModel()
  }

  @action
  async filter(params) {
    this.isLoading = true
    const result = await announcementService.filter(params).finally(() => (this.isLoading = false))
    this.pagedData = result
  }

  @action
  async getAll(params) {
    this.isLoading = true
    params.isActive = true
    this.announcements = await announcementService.getAll(params).finally(() => (this.isLoading = false))
  }

  @action
  async getAnnouncementTypes() {
    this.isLoading = true
    this.announcementTypes = await announcementService.getAnnouncementTypes().finally(() => (this.isLoading = false))
  }
}

export default AnnouncementStore
