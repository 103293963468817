import type { PagedResultDto } from '../dto/pagedResultDto'
import http from '../httpService'
import { notifyError, notifySuccess } from '@lib/helper'
import { L, LNotification } from '@lib/abpUtility'
import { CampaignModel, AwardRecipientModel } from '@models/growth/campaignModel'
import { downloadFile } from '@lib/helperFile'

class CampaignService {
  public async create(body: any) {
    try {
      body.isActive = true
      const res = await http.post('api/services/app/Campaigns/Create', body)
      notifySuccess(LNotification('SUCCESS'), LNotification(L('SAVE_SUCCESSFULLY')))
      return res.data.result
    } catch (e) {
      console.log(e)
    }
  }

  public async filter(params: any): Promise<PagedResultDto<CampaignModel>> {
    const res = await http.get('api/services/app/Campaigns/GetAll', { params })
    const result = res.data.result
    result.items = CampaignModel.assigns(result.items || [])
    return result
  }

  public async update(body: any) {
    const res = await http.put('api/services/app/Campaigns/Update', body)
    notifySuccess(LNotification('SUCCESS'), LNotification(L('SAVE_SUCCESSFULLY')))
    return res.data.result
  }

  public async getById(id): Promise<any> {
    if (!id) {
      notifyError(L('ERROR'), L('ENTITY_NOT_FOUND'))
    }
    const result = await http.get('/api/services/app/Campaigns/Get', { params: { id } })
    return result.data.result
  }

  public async activateOrDeactivate(body) {
    const response = await http.post('api/services/app/Campaigns/Active', body)
    notifySuccess(LNotification('SUCCESS'), LNotification('SAVING_SUCCESSFULLY'))
    return response.data
  }

  public async publishOrRecall(body) {
    const response = await http.post('api/services/app/Campaigns/Publish', body)
    notifySuccess(LNotification('SUCCESS'), LNotification('SAVING_SUCCESSFULLY'))
    return response.data
  }

  public async sendNotify(body) {
    const response = await http.post('api/services/app/Campaigns/SendNotice', body)
    notifySuccess(LNotification('SUCCESS'), LNotification('SAVING_SUCCESSFULLY'))
    return response.data
  }

  public async getRedemptionHistory(params: any): Promise<PagedResultDto<AwardRecipientModel>> {
    const res = await http.get('api/services/app/Campaigns/GetAllCampaignHistory', { params })
    const result = res.data.result
    return result
  }

  public async exportRedemptionHistory(params: any): Promise<any> {
    const res = await http.get('api/Export/ExportCampaignRedeems', { params, responseType: 'blob' })
    downloadFile(res.data, 'campaign-rewarded-export.xlsx')
  }
}

export default new CampaignService()
