import { ExcelIcon, ImageIcon, OtherFileIcon, PdfIcon, WordIcon, PowerPointIcon } from '@components/Icon'
import { OptionModel } from '@models/global'
import { useEffect, useRef } from 'react'

import {
  Autoformat,
  Bold,
  Italic,
  Underline,
  BlockQuote,
  Base64UploadAdapter,
  CloudServices,
  Essentials,
  Heading,
  Image,
  ImageCaption,
  ImageResize,
  ImageStyle,
  ImageToolbar,
  ImageUpload,
  PictureEditing,
  Indent,
  IndentBlock,
  Link,
  List,
  MediaEmbed,
  Mention,
  Paragraph,
  PasteFromOffice,
  Table,
  TableColumnResize,
  TableToolbar,
  TextTransformation
} from 'ckeditor5'

import { L } from './abpUtility'

export const AppConfiguration = {
  appBaseUrl: '',
  remoteServiceBaseUrl: '',
  googleMapKey: 'AIzaSyC43U2-wqXxYEk1RBrTLdkYt3aDoOxO4Fw',
  goongApiKey: 'mxEQZQGwWeQZ3HqyRrp6QoPeBfUHJqf8m3SBookb',
  goongMapKey: 'yfnvKwMxUZFs9OwQSlkB8G9IS1S0z83ARYFHV0Km', // 'v2cU3WJ6KAzUJovMVtMeDgn88bsBtkiZERiRYB3w',
  appLayoutConfig: {
    loader: { type: 'xmas-night', delayTime: 3000 }
  } as any
}
export const tableScrollConfig = { x: 1024, scrollToFirstRowOnChange: true }
export const validateStatus = { validating: 'validating', success: 'success', error: 'error', warning: 'warning' }
export const firebaseConfig = {
  apiKey: 'AIzaSyDC9i8HvJsJK3h9qUNOokDDiRpAxbL243M',
  authDomain: 'home-8f55a.firebaseapp.com',
  databaseURL: 'https://home-8f55a.firebaseio.com',
  projectId: 'home-8f55a',
  storageBucket: 'home-8f55a.appspot.com',
  messagingSenderId: '1056523682598',
  appId: '1:1056523682598:web:328e584d2f15994a4177e7'
}

export const usePrevious = (value) => {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export const AppStatus = {
  withdrawStatus: {
    cancelled: 'CANCELLED',
    processed: 'PROCESSED',
    isDone: (statusCode) => statusCode === 'CANCELLED' || statusCode === 'PROCESSED'
  },
  activeStatus: {},
  announcementStatus: {
    cancelled: 'CANCELLED',
    processed: 'PROCESSED',
    isDone: (statusCode) => statusCode === 'CANCELLED' || statusCode === 'PROCESSED'
  },
  removalStatus: {
    requested: 'REQUESTED',
    received: 'RECEIVED',
    onTheWay: 'ONTHEWAY',
    processing: 'PROCESSING',
    waitingForComplete: 'WAITINGFORCOMPLETE',
    completed: 'COMPLETED',
    cancelled: 'CANCELLED',
    wait: 'WAIT',
    notFound: 'NOTFOUND'
  },
  removeStatusId: {
    requested: 1,
    received: 2,
    onTheWay: 3,
    processing: 4,
    waitingForComplete: 5,
    completed: 6,
    cancelled: 7,
    wait: 8,
    notFound: 9
  },
  readyStatus: {
    ready: 'READY',
    received: 'RECEIVED',
    inprogress: 'INPROGRESS',
    notReady: 'NOT_READY'
  }
}

export const appStatusColors = {
  success: '#689F38',
  error: '#EB7077',
  valid: '#689F38',
  expired: '#CCCCCC',
  received: '#2463EB',
  inprogress: '#CA8A03'
}
const AppConsts = {
  title: 'Sben',
  align: {
    right: 'right' as const,
    left: 'left' as const,
    center: 'center' as const
  },
  dataType: {
    string: 'string' as const,
    number: 'number' as const,
    boolean: 'boolean' as const,
    method: 'method' as const,
    regexp: 'regexp' as const,
    integer: 'integer' as const,
    float: 'float' as const,
    object: 'object' as const,
    enum: 'enum' as const,
    date: 'date' as const,
    url: 'url' as const,
    hex: 'hex' as const,
    email: 'email' as const
  },
  formVerticalLayout: {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 },
      lg: { span: 24 },
      xl: { span: 24 },
      xxl: { span: 24 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 },
      lg: { span: 24 },
      xl: { span: 24 },
      xxl: { span: 24 }
    }
  },
  formHorizontalLayout: {
    labelCol: {
      xs: { span: 12 },
      sm: { span: 12 },
      md: { span: 12 },
      lg: { span: 6 },
      xl: { span: 6 },
      xxl: { span: 4 }
    },
    wrapperCol: {
      xs: { span: 12 },
      sm: { span: 12 },
      md: { span: 12 },
      lg: { span: 18 },
      xl: { span: 18 },
      xxl: { span: 20 }
    }
  },
  projectCategoryTarget: {
    unitType: 'UNITTYPE',
    unitStatus: 'UNITSTATUS',
    memberRole: 'MEMBERROLE',
    memberType: 'MEMBERTYPE'
  },
  ratingOptions: [
    {
      value: 1
    },
    {
      value: 2
    },
    {
      value: 3
    },
    {
      value: 4
    },
    {
      value: 5
    }
  ],
  escrowOptions: [100000, 200000, 500000, 800000],
  receiveStatus: [
    {
      name: 'All',
      value: ' ',
      get label() {
        return 'ALL'
      }
    },
    {
      name: 'Active',
      value: 'true',
      get label() {
        return 'RECEIVE_REQUEST'
      }
    },
    {
      name: 'Inactive',
      value: 'false',
      get label() {
        return 'NOT_RECEIVE_REQUEST'
      }
    }
  ],
  confirmedStatus: [
    {
      name: 'All',
      value: ' ',
      get label() {
        return 'ALL'
      }
    },
    {
      name: 'Active',
      value: 'true',
      get label() {
        return 'CONFIRMED'
      }
    },
    {
      name: 'Inactive',
      value: 'false',
      get label() {
        return 'UNCONFIRMED'
      }
    }
  ],
  warningCheatStatus: [
    {
      name: 'All',
      value: ' ',
      get label() {
        return L('ALL')
      }
    },
    {
      name: 'Warning',
      value: 'true',
      get label() {
        return L('WARNING')
      }
    },
    {
      name: 'Non Warning',
      value: 'false',
      get label() {
        return L('NON_WARNING')
      }
    }
  ],
  activeStatus: [
    {
      name: 'All',
      value: ' ',
      get label() {
        return L('ALL')
      }
    },
    {
      name: 'Active',
      value: 'true',
      get label() {
        return L('ACTIVE')
      }
    },
    {
      name: 'Inactive',
      value: 'false',
      get label() {
        return L('INACTIVE')
      }
    }
  ],
  approvedStatus: [
    {
      name: 'All',
      value: ' ',
      get label() {
        return L('ALL')
      }
    },
    {
      name: 'Approved',
      value: 'true',
      get label() {
        return L('APPROVED')
      }
    },
    {
      name: 'Inapproved',
      value: 'false',
      get label() {
        return L('INAPPROVED')
      }
    }
  ],
  authenticationOptions: [
    {
      name: 'All',
      value: ' ',
      get label() {
        return 'ALL'
      }
    },
    {
      name: 'Active',
      value: '1',
      get label() {
        return 'AUTHENTICATED'
      }
    },
    {
      name: 'Inactive',
      value: '0',
      get label() {
        return 'UNAUTHENTICATED'
      }
    }
  ],
  emailAuthenticationStatus: [
    {
      name: 'All',
      value: ' ',
      get label() {
        return 'ALL'
      }
    },
    {
      name: 'Authentication',
      value: 'true',
      get label() {
        return 'EMAIL_AUTHENTICATION'
      }
    },
    {
      name: 'Unauthentication',
      value: 'false',
      get label() {
        return 'EMAIL_UNAUTHENTICATION'
      }
    }
  ],
  phoneAuthenticationStatus: [
    {
      name: 'All',
      value: ' ',
      get label() {
        return 'ALL'
      }
    },
    {
      name: 'Authentication',
      value: 'true',
      get label() {
        return 'PHONE_AUTHENTICATION'
      }
    },
    {
      name: 'Unauthentication',
      value: 'false',
      get label() {
        return 'PHONE_UNAUTHENTICATION'
      }
    }
  ],
  receivingRequestStatus: [
    {
      name: 'All',
      value: ' ',
      get label() {
        return 'ALL'
      }
    },
    {
      name: 'Ready to receive',
      value: 'true',
      get label() {
        return 'READY_TO_RECEIVE'
      }
    },
    {
      name: 'Not receive',
      value: 'false',
      get label() {
        return 'NOT_READY_TO_RECEIVE'
      }
    }
  ],
  // Truck ready to receive request status
  readyStatus: {
    ready: {
      name: 'TRUCK_READY',
      value: AppStatus.readyStatus.ready,
      colorCode: appStatusColors.success
    },
    notReady: {
      name: 'TRUCK_NOT_READY',
      value: AppStatus.readyStatus.notReady,
      colorCode: appStatusColors.error
    },
    received: {
      name: 'TRUCK_RECEIVED',
      value: AppStatus.readyStatus.received,
      colorCode: appStatusColors.received
    },
    inprogress: {
      name: 'TRUCK_IN_PROGRESS',
      value: AppStatus.readyStatus.inprogress,
      colorCode: appStatusColors.inprogress
    }
  },
  genders: [
    { name: 'GENDER_MALE', value: true },
    { name: 'GENDER_FEMALE', value: false },
    { name: 'GENDER_OTHER', value: null }
  ],
  genders2: [
    {
      get label() {
        return 'GENDER_MALE'
      },
      value: 'true'
    },
    {
      get label() {
        return 'GENDER_FEMALE'
      },
      value: 'false'
    },
    {
      get label() {
        return 'GENDER_OTHER'
      },
      value: 'null'
    }
  ],
  mobileApplicationTypes: [
    new OptionModel(1, 'ALL'),
    new OptionModel(2, 'APP_CLIENT'),
    new OptionModel(3, 'APP_PARTNER')
  ],
  mobileApplicationTypeById: {
    1: 'ALL',
    2: 'APP_CLIENT',
    3: 'APP_PARTNER'
  },
  workingTimes: [
    { name: 'DAY', value: 'day' },
    { name: 'NIGHT', value: 'night' },
    { name: 'MIDNIGHT', value: 'midnight' },
    { name: 'SUNDAY', value: 'sunday' },
    { name: 'HOLIDAY', value: 'holiday' }
  ],
  userManagement: {
    defaultAdminUserName: 'admin'
  },
  localization: {
    defaultLocalizationSourceName: 'WebLabel',
    sourceWebNotification: 'WebNotification',
    sourceWebError: 'WebError',
    sourceWebMainMenu: 'WebMainMenu',
    sourceWebCategory: 'WebCategory'
  },
  authorization: {
    encrptedAuthTokenName: 'enc_auth_token',
    projectId: 'projectId',
    targetApplication: 1
  },
  validate: {
    maxNumber: 999999999999
  },
  masterDataTargets: {
    WORK_ORDER_TYPE: 'WorkOrderType',
    UNIT_TYPE: 'UnitType',
    UNIT_STATUS: 'UnitStatus',
    RESIDENT_TYPE: 'ResidentType',
    RESIDENT_ROLE: 'ResidentRole'
  },
  notificationTypes: {
    all: 0,
    sms: 1,
    email: 2,
    inApp: 3
  },
  announcementTypeCodes: {
    updateApp: 'UpdateApp',
    picture: 'Picture',
    video: 'Video'
  },
  announcementTypeIds: {
    updateApp: 1,
    picture: 2,
    video: 3
  },
  announcementTypeIcon: {
    1: 'assets/icons/announcement-horn.svg',
    2: 'assets/icons/announcement-image.svg',
    3: 'assets/icons/announcement-video.svg'
  },
  announcementMethodTypes: {
    all: 0,
    email: 1,
    inApp: 2
  },
  announcementMethodTypeKeys: {
    0: 'ALL',
    1: 'EMAIL', //(allow HTML)
    2: 'INAPP'
  },
  announcementStatus: {
    readyForPublish: 1,
    sending: 2,
    completed: 3,
    failed: 4
  },
  announcementStatusKeys: {
    0: 'ANNOUNCEMENT_STATUS_PROCESSING',
    1: 'ANNOUNCEMENT_STATUS_READY_FOR_PUBLISH',
    2: 'ANNOUNCEMENT_STATUS_SENDING',
    3: 'ANNOUNCEMENT_STATUS_COMPLETED',
    4: 'ANNOUNCEMENT_STATUS_FAILED'
  },
  monthNamesShort: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'],
  timeUnits: {
    hours: 'HOURS',
    days: 'DAYS',
    minutes: 'MINUTES'
  },

  cashAdvanceTransactionTypes: {
    receipt: 1,
    expenseMandate: 2
  },
  cashAdvanceTransactionOptions: [
    {
      value: ' ',
      name: 'All',
      get label() {
        return L('ALL')
      }
    },
    {
      value: 1,
      name: 'RECEIPT',
      get label() {
        return L('RECEIPT')
      }
    },
    {
      value: 2,
      name: 'EXPENSE',
      get label() {
        return L('EXPENSE')
      }
    }
  ],
  cashChanelOptions: [
    {
      value: ' ',
      name: 'All',
      get label() {
        return L('ALL')
      }
    },
    {
      value: 1,
      name: 'CASH',
      get label() {
        return L('CASH')
      }
    },
    {
      value: 2,
      name: 'BANK',
      get label() {
        return L('BANK')
      }
    },
    {
      value: 3,
      name: 'PAYMENT_ONLINE',
      get label() {
        return L('PAYMENT_ONLINE')
      }
    }
  ],
  dayOfWeek: [
    {
      value: 0,
      name: 'Sunday'
    },
    {
      value: 1,
      name: 'Monday'
    },
    {
      value: 2,
      name: 'Tuesday'
    },
    {
      value: 3,
      name: 'Wednesday'
    },
    {
      value: 4,
      name: 'Thursday'
    },
    {
      value: 5,
      name: 'Friday'
    },
    {
      value: 6,
      name: 'Saturday'
    }
  ],
  targetApplicationOptions: [
    { value: 1, id: 1, label: 'ADMIN' },
    { value: 2, id: 2, label: 'CUSTOMER' },
    { value: 3, id: 3, label: 'PARTNER' }
  ],
  cancelModuleIds: [
    { value: 17, id: 17, label: 'REMOVAL' },
    { value: 19, id: 19, label: 'TRIP' }
  ],
  paymentMethod: [
    { value: 1, id: 1, label: 'CASH' },
    { value: 2, id: 2, label: 'E_WALLET' }
  ]
}

export const PROMOTION_DISCOUNT_BY = {
  PERCENT: 2,
  VALUE: 3
}

export const PROMOTION_OBJECT = {
  NOT_SET: 1,
  APPLY_FOR_FIRST_USED: 2,
  APPLY_FOR_SPECIFIC_USER: 3,
  APPLY_FOR_SPECIFIC_PRODUCT_TYPE: 4
}

export const PAYMENT_METHOD_MAPPING = {
  '1': 'CASH',
  '2': 'CASH_ADVANCE'
}

export const OUTSTANDING_STATUS_MAPPING = {
  1: 'OUTSTANDING',
  2: 'PAID',
  3: 'CASH',
  4: 'CANCELLED_STATUS'
}

export const loginSteps = {
  login: 1,
  projectSelect: 2
}
export const loginMethods = {
  systemAccount: 1,
  socialAccount: 2,
  phoneNumber: 3
}

export const workflowEvent = {
  init: 'InitWorkflow'
}
export const documentTypes = {
  image: 'IMAGE',
  document: 'DOCUMENT'
}
export const cookieKeys = {
  encToken: 'enc_auth_token'
}
export const defaultAvatar = '/assets/images/logo.svg'
export const dateFormat = 'DD/MM/YYYY'
export const dateTimeFormat = 'DD/MM/YYYY HH:mm'
export const dateTimeSecondFormat = 'DD/MM/YYYY HH:mm:ss'
export const yearFormat = 'YYYY'
export const timeFormat = 'HH:mm'
export const phoneRegex = /^[+]?\(?([0-9]{0,3})?\)?[-. ]?([0-9]{1,3})?[-. ]?([0-9]{1,3})[-. ]?([0-9]{1,5})$/
export const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const modules = [
  {
    get name() {
      return 'MODULE_WORKORDER'
    },
    id: 13
  },
  {
    get name() {
      return 'MODULE_FEEDBACK'
    },
    id: 20
  }
]
export const moduleIds = {
  dismantlingRequest: 14,
  outstanding: 13,
  feedback: 20,
  comment: 1002,
  removalRequest: 17,
  hiringRequest: 19,
  visitor: 1003,
  planMaintenance: 18,
  inventory: 34,
  order: 47,
  inspection: 41,
  cogs: 47,
  ratingBadge: 301,
  feedbackType: 201,
  feedbackCategory: 201,
  transportationCost: 47,
  quotationProject: 47
}
export const modulePrefix = {
  13: 'OUTSTANDING_',
  20: 'FEEDBACK_WF_',
  14: 'DISMANTLING_REQUEST_'
}
export const moduleFile = {
  announcement: 'Announcement',
  project: 'Project',
  workOrder: 'WorkOrder',
  feedback: 'Feedback',
  news: 'News',
  event: 'Event',
  reservation: 'Reservation',
  chatMessage: 'ChatMessages',
  amenity: 'Amenities',
  visitor: 'Visitor',
  company: 'Company',
  contract: 'Contract',
  contractCategory: 'ContractCategory',
  buildingDirectory: 'BuildingDirectory',
  planMaintenance: 'PlanMaintenance',
  asset: 'AssetManagement',
  shopOwner: 'ShopOwner',
  product: 'Product',
  inventory: 'Inventory',
  inventoryStockIn: 'InventoryStock',
  inventoryStockOut: 'InventoryAllocate',
  campaign: 'Campaign'
}
export const notificationMethod = {
  1: 'SMS',
  2: 'EMAIL', //(allow HTML)
  3: 'INAPP'
}
export const notificationMethods = [
  {
    get name() {
      return 'SMS'
    },
    value: 1,
    id: 1
  },
  {
    get name() {
      return 'EMAIL'
    },
    value: 2,
    id: 2
  },
  {
    get name() {
      return 'INAPP'
    },
    value: 3,
    id: 3
  }
]
export const userGroups = [
  {
    get name() {
      return 'STAFF'
    },
    id: 1
  },
  {
    get name() {
      return 'CUSTOMER'
    },
    id: 2
  },
  {
    get name() {
      return 'PARTNER'
    },
    id: 3
  }
]

export const backgroundColors = [
  '#FAC51D',
  '#66BD6D',
  '#FAA026',
  '#29BB9C',
  '#E96B56',
  '#55ACD2',
  '#B7332F',
  '#2C83C9',
  '#9166B8',
  '#92E7E8'
]
export const getBackgroundColorByIndex = (arrayIndex) => {
  const index = arrayIndex % backgroundColors.length
  return backgroundColors[index]
}

export const moduleAvatar = {
  myProfile: 'myProfile',
  staff: 'Staff',
  resident: 'Resident',
  shopOwner: 'ShopOwner',
  project: 'Project',
  colorByLetter: (letter) => {
    if (!backgroundColors || !letter) return '#fff'

    const charCode = letter.charCodeAt(0)
    return getBackgroundColorByIndex(charCode)
  }
}
export const wfFieldTypes = {
  text: 0,
  number: 1,
  money: 2,
  dateTime: 3,
  list: 4
}

export const ckeditorToolbar = {
  toolbar: [
    'undo',
    'redo',
    '|',
    'heading',
    '|',
    'bold',
    'italic',
    'underline',
    '|',
    'link',
    'uploadImage',
    'insertTable',
    'blockQuote',
    'mediaEmbed',
    '|',
    'bulletedList',
    'numberedList',
    '|',
    'outdent',
    'indent'
  ],
  heading: {
    options: [
      {
        model: 'paragraph',
        view: '',
        title: 'Paragraph',
        class: 'ck-heading_paragraph'
      },
      {
        model: 'heading1' as const,
        view: 'h1',
        title: 'Heading 1',
        class: 'ck-heading_heading1'
      },
      {
        model: 'heading2' as const,
        view: 'h2',
        title: 'Heading 2',
        class: 'ck-heading_heading2'
      },
      {
        model: 'heading3' as const,
        view: 'h3',
        title: 'Heading 3',
        class: 'ck-heading_heading3'
      },
      {
        model: 'heading4' as const,
        view: 'h4',
        title: 'Heading 4',
        class: 'ck-heading_heading4'
      }
    ] as any[]
  },
  image: {
    resizeOptions: [
      {
        name: 'resizeImage:original',
        label: 'Default image width',
        value: null
      },
      {
        name: 'resizeImage:50',
        label: '50% page width',
        value: '50'
      },
      {
        name: 'resizeImage:75',
        label: '75% page width',
        value: '75'
      }
    ],
    toolbar: [
      'imageTextAlternative',
      'toggleImageCaption',
      '|',
      'imageStyle:inline',
      'imageStyle:wrapText',
      'imageStyle:breakText',
      '|',
      'resizeImage'
    ]
  },
  link: {
    addTargetToExternalLinks: true,
    defaultProtocol: 'https://'
  },
  table: {
    contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells']
  },
  plugins: [
    Autoformat,
    BlockQuote,
    Bold,
    CloudServices,
    Essentials,
    Heading,
    Image,
    ImageCaption,
    ImageResize,
    ImageStyle,
    ImageToolbar,
    ImageUpload,
    Base64UploadAdapter,
    Indent,
    IndentBlock,
    Italic,
    Link,
    List,
    MediaEmbed,
    Mention,
    Paragraph,
    PasteFromOffice,
    PictureEditing,
    Table,
    TableColumnResize,
    TableToolbar,
    TextTransformation,
    Underline
  ],
  removeButtons:
    'Save,Templates,Cut,NewPage,Preview,Copy,Paste,PasteText,PasteFromWord,Find,Replace,SelectAll,Scayt,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,ShowBlocks,About,Flash,PageBreak,HorizontalRule,Language,BidiRtl,BidiLtr,Blockquote,CreateDiv,Smiley,Iframe'
}
export const mimeType = {
  'application/pdf': PdfIcon,
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ExcelIcon,
  'application/vnd.ms-excel': ExcelIcon,
  'application/msword': WordIcon,
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': WordIcon,
  'application/vnd.ms-powerpoint': PowerPointIcon,
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': PowerPointIcon,
  'image/jpeg': ImageIcon,
  'image/png': ImageIcon,
  other: OtherFileIcon
}
export const mimeTypeToImagePath = {
  'application/pdf': '/assets/icons/pdf.svg',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': '/assets/icons/excel.svg',
  'application/vnd.ms-excel': '/assets/icons/excel.svg',
  'application/msword': '/assets/icons/word.svg',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': '/assets/icons/word.svg',
  'application/vnd.ms-powerpoint': '/assets/icons/power-point.svg',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': '/assets/icons/power-point.svg',
  'image/jpeg': '/assets/icons/image-file.svg',
  'image/png': '/assets/icons/image-file.svg',
  other: '/assets/icons/other-file.svg'
}
export const appPermissions = {
  adminLanguage: {
    page: 'PagesAdministration.Languages',
    create: 'PagesAdministration.Languages.Create',
    read: 'PagesAdministration.Languages.Read',
    update: 'PagesAdministration.Languages.Update',
    delete: 'PagesAdministration.Languages.Delete',
    changeText: 'PagesAdministration.Languages.ChangeTexts'
  },
  adminRole: {
    page: 'PagesAdministration.Roles',
    create: 'PagesAdministration.Roles.Create',
    read: 'PagesAdministration.Roles.Read',
    update: 'PagesAdministration.Roles.Update',
    delete: 'PagesAdministration.Roles.Delete'
  },
  adminUser: {
    page: 'PagesAdministration.Users',
    create: 'PagesAdministration.Users.Create',
    read: 'PagesAdministration.Users.Read',
    update: 'PagesAdministration.Users.Update',
    delete: 'PagesAdministration.Users.Delete'
  },
  adminTenant: {
    page: 'PagesAdministration.Roles',
    create: 'PagesAdministration.Roles.Create',
    read: 'PagesAdministration.Roles.Read',
    update: 'PagesAdministration.Roles.Update',
    delete: 'PagesAdministration.Roles.Delete'
  },
  adminMasterData: {
    page: 'PagesAdministration.MasterData',
    create: 'PagesAdministration.MasterData.Create',
    read: 'PagesAdministration.MasterData.Read',
    update: 'PagesAdministration.MasterData.Update',
    delete: 'PagesAdministration.MasterData.Delete',
    detail: 'PagesAdministration.MasterData.Detail'
  },
  workflow: {
    page: 'PagesAdministration.Workflow',
    create: 'PagesAdministration.Workflow.Create',
    read: 'PagesAdministration.Workflow.Read',
    update: 'PagesAdministration.Workflow.Update',
    delete: 'PagesAdministration.Workflow.Delete',
    detail: 'PagesAdministration.Workflow.Detail'
  },
  notificationTemplate: {
    page: 'PagesAdministration.NotificationTemplate',
    create: 'PagesAdministration.NotificationTemplate.Create',
    read: 'PagesAdministration.NotificationTemplate.Read',
    update: 'PagesAdministration.NotificationTemplate.Update',
    delete: 'PagesAdministration.NotificationTemplate.Delete',
    detail: 'PagesAdministration.NotificationTemplate.Detail'
  },
  termCondition: {
    page: 'PagesAdministration.MasterData',
    create: 'PagesAdministration.MasterData.Create',
    read: 'PagesAdministration.MasterData.Read',
    update: 'PagesAdministration.MasterData.Update',
    delete: 'PagesAdministration.MasterData.Delete',
    detail: 'PagesAdministration.MasterData.Detail'
  },
  dashboard: {
    page: 'PagesAdministration.Dashboard',
    fee: 'PagesAdministration.Dashboard.FeeStatement',
    jobRequest: 'PagesAdministration.Dashboard.WorkOrder'
  },
  settingEmail: {
    page: 'PagesAdministration.SettingEmail'
  },
  truckBrand: {
    page: 'PagesAdministration.MasterData',
    create: 'PagesAdministration.MasterData.Create',
    read: 'PagesAdministration.MasterData.Read',
    update: 'PagesAdministration.MasterData.Update',
    delete: 'PagesAdministration.MasterData.Delete',
    detail: 'PagesAdministration.MasterData.Export',
    export: 'PagesAdministration.MasterData.Export',
    import: 'PagesAdministration.MasterData.Import'
  },
  truckType: {
    page: 'PagesAdministration.MasterData',
    create: 'PagesAdministration.MasterData.Create',
    read: 'PagesAdministration.MasterData.Read',
    update: 'PagesAdministration.MasterData.Update',
    delete: 'PagesAdministration.MasterData.Delete',
    export: 'PagesAdministration.MasterData.Export'
  },
  productType: {
    page: 'PagesAdministration.MasterData',
    create: 'PagesAdministration.MasterData.Create',
    read: 'PagesAdministration.MasterData.Read',
    update: 'PagesAdministration.MasterData.Update',
    delete: 'PagesAdministration.MasterData.Delete',
    detail: 'PagesAdministration.MasterData.Export',
    export: 'PagesAdministration.MasterData.Export',
    import: 'PagesAdministration.MasterData.Import'
  },
  status: {
    page: 'PagesAdministration.MasterData',
    create: 'PagesAdministration.MasterData.Create',
    read: 'PagesAdministration.MasterData.Read',
    update: 'PagesAdministration.MasterData.Update',
    delete: 'PagesAdministration.MasterData.Delete',
    detail: 'PagesAdministration.MasterData.Export',
    export: 'PagesAdministration.MasterData.Export',
    import: 'PagesAdministration.MasterData.Import'
  },
  feedbackType: {
    page: 'PagesAdministration.MasterData',
    create: 'PagesAdministration.MasterData.Create',
    read: 'PagesAdministration.MasterData.Read',
    update: 'PagesAdministration.MasterData.Update',
    delete: 'PagesAdministration.MasterData.Delete',
    detail: 'PagesAdministration.MasterData.Export',
    export: 'PagesAdministration.MasterData.Export',
    import: 'PagesAdministration.MasterData.Import'
  },
  feedbackCategory: {
    page: 'PagesAdministration.MasterData',
    create: 'PagesAdministration.MasterData.Create',
    read: 'PagesAdministration.MasterData.Read',
    update: 'PagesAdministration.MasterData.Update',
    delete: 'PagesAdministration.MasterData.Delete',
    detail: 'PagesAdministration.MasterData.Export',
    export: 'PagesAdministration.MasterData.Export',
    import: 'PagesAdministration.MasterData.Import'
  },
  ratingBadge: {
    page: 'PagesAdministration.MasterData',
    create: 'PagesAdministration.MasterData.Create',
    read: 'PagesAdministration.MasterData.Read',
    update: 'PagesAdministration.MasterData.Update',
    delete: 'PagesAdministration.MasterData.Delete',
    detail: 'PagesAdministration.MasterData.Export',
    export: 'PagesAdministration.MasterData.Export',
    import: 'PagesAdministration.MasterData.Import'
  },
  promotion: {
    page: 'PagesAdministration.Promotion',
    create: 'PagesAdministration.Promotion.Create',
    read: 'PagesAdministration.Promotion.Read',
    update: 'PagesAdministration.Promotion.Update',
    delete: 'PagesAdministration.Promotion.Delete',
    detail: 'PagesAdministration.Promotion.Detail',
    export: 'PagesAdministration.Promotion.Export',
    import: 'PagesAdministration.Promotion.Import'
  },
  campaign: {
    page: 'PagesAdministration.Campaign',
    create: 'PagesAdministration.Campaign.Create',
    read: 'PagesAdministration.Campaign.Read',
    update: 'PagesAdministration.Campaign.Update',
    delete: 'PagesAdministration.Campaign.Delete',
    detail: 'PagesAdministration.Campaign.Detail',
    export: 'PagesAdministration.Campaign.Export',
    import: 'PagesAdministration.Campaign.Import'
  },
  reasonReject: {
    page: 'PagesAdministration.MasterData',
    create: 'PagesAdministration.MasterData.Create',
    read: 'PagesAdministration.MasterData.Read',
    update: 'PagesAdministration.MasterData.Update',
    delete: 'PagesAdministration.MasterData.Delete',
    detail: 'PagesAdministration.MasterData.Export',
    export: 'PagesAdministration.MasterData.Export',
    import: 'PagesAdministration.MasterData.Import'
  },
  reasonWaiting: {
    page: 'PagesAdministration.MasterData',
    create: 'PagesAdministration.MasterData.Create',
    read: 'PagesAdministration.MasterData.Read',
    update: 'PagesAdministration.MasterData.Update',
    delete: 'PagesAdministration.MasterData.Delete',
    detail: 'PagesAdministration.MasterData.Export',
    export: 'PagesAdministration.MasterData.Export',
    import: 'PagesAdministration.MasterData.Import'
  },
  transportationCost: {
    page: 'PagesAdministration.MasterData',
    create: 'PagesAdministration.MasterData.Create',
    read: 'PagesAdministration.MasterData.Read',
    update: 'PagesAdministration.MasterData.Update',
    delete: 'PagesAdministration.MasterData.Delete',
    detail: 'PagesAdministration.MasterData.Export',
    export: 'PagesAdministration.MasterData.Export',
    import: 'PagesAdministration.MasterData.Import'
  },
  // Account
  staff: {
    page: 'PagesAdministration.Staff',
    create: 'PagesAdministration.Staff.Create',
    read: 'PagesAdministration.Staff.Read',
    update: 'PagesAdministration.Staff.Update',
    delete: 'PagesAdministration.Staff.Delete',
    detail: 'PagesAdministration.Staff.Detail'
  },
  customer: {
    page: 'PagesAdministration.Customer',
    create: 'PagesAdministration.Customer.Create',
    read: 'PagesAdministration.Customer.Read',
    update: 'PagesAdministration.Customer.Update',
    updateEmployee: 'PagesAdministration.Customer.UpdateEmployee',
    delete: 'PagesAdministration.Customer.Delete',
    detail: 'PagesAdministration.Customer.Detail',
    export: 'PagesAdministration.Customer.Export'
  },
  partner: {
    page: 'PagesAdministration.Partner',
    create: 'PagesAdministration.Partner.Create',
    read: 'PagesAdministration.Partner.Read',
    update: 'PagesAdministration.Partner.Update',
    delete: 'PagesAdministration.Partner.Delete',
    detail: 'PagesAdministration.Partner.Detail',
    export: 'PagesAdministration.Partner.Export'
  },
  partnerAnalytic: {
    page: 'PagesAdministration.Dashboard.PartnerStatistic',
    export: 'PagesAdministration.Dashboard.PartnerStatisticExport'
  },
  truckManagement: {
    page: 'PagesAdministration.TruckManagement',
    create: 'PagesAdministration.TruckManagement.Create',
    read: 'PagesAdministration.TruckManagement.Read',
    update: 'PagesAdministration.TruckManagement.Update',
    delete: 'PagesAdministration.TruckManagement.Delete',
    detail: 'PagesAdministration.TruckManagement.Detail'
  },
  // Services
  feedback: {
    page: 'PagesAdministration.Feedback',
    create: 'PagesAdministration.Feedback.Create',
    read: 'PagesAdministration.Feedback.Read',
    update: 'PagesAdministration.Feedback.Update',
    delete: 'PagesAdministration.Feedback.Delete',
    detail: 'PagesAdministration.Feedback.Detail',
    myWorkOrder: 'PagesAdministration.Feedback.MyWorkorder',
    export: 'PagesAdministration.Feedback.Export'
  },
  news: {
    page: 'PagesAdministration.Event',
    create: 'PagesAdministration.Event.Create',
    read: 'PagesAdministration.Event.Read',
    update: 'PagesAdministration.Event.Update',
    delete: 'PagesAdministration.Event.Delete',
    detail: 'PagesAdministration.Event.Detail'
  },
  event: {
    page: 'PagesAdministration.Event',
    create: 'PagesAdministration.Event.Create',
    read: 'PagesAdministration.Event.Read',
    update: 'PagesAdministration.Event.Update',
    delete: 'PagesAdministration.Event.Delete',
    detail: 'PagesAdministration.Event.Detail'
  },
  announcement: {
    page: 'PagesAdministration.Announcement',
    create: 'PagesAdministration.Announcement.Create',
    read: 'PagesAdministration.Announcement.Read',
    update: 'PagesAdministration.Announcement.Update',
    delete: 'PagesAdministration.Announcement.Delete',
    detail: 'PagesAdministration.Announcement.Detail'
  },
  system: {
    paymentSetting: 'PagesAdministration.System.Settings'
  },
  // Finance
  cashAdvance: {
    page: 'PagesAdministration.CashAdvance',
    create: 'PagesAdministration.CashAdvance.Create',
    read: 'PagesAdministration.CashAdvance.Read',
    update: 'PagesAdministration.CashAdvance.Update',
    delete: 'PagesAdministration.CashAdvance.Delete',
    detail: 'PagesAdministration.CashAdvance.Detail',
    export: 'PagesAdministration.CashAdvance.Export'
  },
  receipt: {
    page: 'PagesAdministration.Receipt',
    create: 'PagesAdministration.Receipt.Create',
    read: 'PagesAdministration.Receipt.Read',
    update: 'PagesAdministration.Receipt.Update',
    delete: 'PagesAdministration.Receipt.Delete',
    export: 'PagesAdministration.Receipt.Export'
  },
  expenseMandate: {
    page: 'PagesAdministration.ExpenseMandateRequest',
    create: 'PagesAdministration.ExpenseMandateRequest.Create',
    read: 'PagesAdministration.ExpenseMandateRequest.Read',
    update: 'PagesAdministration.ExpenseMandateRequest.Update',
    delete: 'PagesAdministration.ExpenseMandateRequest.Delete',
    export: 'PagesAdministration.ExpenseMandateRequest.Export'
  },
  withdraw: {
    page: 'PagesAdministration.Withdraw',
    create: 'PagesAdministration.Withdraw.Create',
    read: 'PagesAdministration.Withdraw.Read',
    update: 'PagesAdministration.Withdraw.Update',
    delete: 'PagesAdministration.Withdraw.Delete',
    detail: 'PagesAdministration.Withdraw.Detail'
  },
  deposit: {
    page: 'PagesAdministration.CashAdvance',
    create: 'PagesAdministration.CashAdvance.Create',
    read: 'PagesAdministration.CashAdvance.Read',
    update: 'PagesAdministration.CashAdvance.Update',
    delete: 'PagesAdministration.CashAdvance.Delete',
    detail: 'PagesAdministration.CashAdvance.Detail'
  },
  feeType: {
    page: 'PagesAdministration.MasterData',
    create: 'PagesAdministration.MasterData.Create',
    read: 'PagesAdministration.MasterData.Read',
    update: 'PagesAdministration.MasterData.Update',
    delete: 'PagesAdministration.MasterData.Delete'
  },
  holiday: {
    page: 'PagesAdministration.Holiday',
    create: 'PagesAdministration.Holiday.Create',
    read: 'PagesAdministration.Holiday.Read',
    update: 'PagesAdministration.Holiday.Update',
    delete: 'PagesAdministration.Holiday.Delete',
    detail: 'PagesAdministration.Holiday.Detail'
  },
  removal: {
    page: 'PagesAdministration.RemovalRequest',
    create: 'PagesAdministration.RemovalRequest.Create',
    read: 'PagesAdministration.RemovalRequest.Read',
    update: 'PagesAdministration.RemovalRequest.Update',
    delete: 'PagesAdministration.RemovalRequest.Delete',
    detail: 'PagesAdministration.RemovalRequest.Detail',
    export: 'PagesAdministration.RemovalRequest.Export'
  },
  applicationSetting: {
    page: 'PagesAdministration.System.Settings'
  },
  outstandingReport: {
    page: 'PagesAdministration.RemovalRequestOutstanding',
    read: 'PagesAdministration.RemovalRequestOutstanding.Read',
    update: 'PagesAdministration.RemovalRequestOutstanding.Update',
    detail: 'PagesAdministration.RemovalRequestOutstanding.Detail',
    export: 'PagesAdministration.RemovalRequestOutstanding.Export',
    myOutstanding: 'PagesAdministration.RemovalRequestOutstanding.MyOutstanding'
  }
}

// Administrative Level
export enum AdministrativeLevel {
  street_number = 'street_number',
  administrative_area_level_1 = 'administrative_area_level_1',
  administrative_area_level_2 = 'administrative_area_level_2',
  administrative_area_level_3 = 'administrative_area_level_3',
  undefined = 'undefined'
}

// Notification
export const notificationTypes = {
  text: 1,
  download: 2,
  gotoDetail: 3,
  removalWarningLv1: 4,
  removalWarningLv2: 5
}

// fileType
export const fileTypeGroup = {
  images: ['.png', '.jpg', '.jpeg'],
  videos: ['.mp4', '.3gp', '.mov'],
  documents: ['.csv', '.xlsx', '.pdf', '.doc', '.docx'],
  documentAndImage: ['.csv', '.xlsx', '.pdf', '.doc', '.docx', '.png', '.jpg', '.jpeg']
}

// Layout constant
export const themeByEvent = {
  events: {
    default: 'default',
    xmasSanta: 'xmas-santa',
    xmasHouse: 'xmas-house',
    xmasNight: 'xmas-night',
    flowers: 'flowers'
  }
}

export const retrieveTypes = {
  administrative_area_level_1: ['(cities)'],
  administrative_area_level_2: ['(regions)'],
  undefined: []
}
export const rangePickerPlaceholder: any = () => {
  const label = [
    {
      get label() {
        return L('START_DATE')
      }
    },
    {
      get label() {
        return L('END_DATE')
      }
    }
  ]
  return label.map((i: any) => i.label)
}

// DASHBOARD
export const TARGET_APPLICATION = {
  admin: 1,
  customer: 2,
  partner: 3
}

export const TRIP_OR_REQUEST = {
  request: 17,
  trip: 19
}

export const PARTNER_STATISTIC_SORT = {
  totalRequestAsc: 1,
  totalRequestDesc: 2,
  totalBlockAsc: 3,
  totalBlockDesc: 4,
  totalWarningCheatAsc: 5,
  totalWarningCheatDesc: 6,
  totalTripAsc: 7,
  totalTripDesc: 8
}

export default AppConsts
