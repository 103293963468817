import { action, makeObservable, observable } from 'mobx'

import type { PagedResultDto } from '../../services/dto/pagedResultDto'
import promotionService from '@services/growth/promotionService'

class PromotionStore {
  @observable isLoading!: boolean
  @observable editPromotion!: any
  @observable pagedData!: PagedResultDto<any>
  @observable promotionHistory: PagedResultDto<any> = { items: [], totalCount: 0 }
  constructor() {
    this.pagedData = { items: [], totalCount: 0 }
    makeObservable(this)
  }

  @action
  async create(body) {
    this.isLoading = true
    await promotionService.create(body).finally(() => {
      this.isLoading = false
    })
  }
  @action
  async getHistory(params: any) {
    this.isLoading = true
    this.promotionHistory = await promotionService.getHistory(params).finally(() => (this.isLoading = false))
  }

  @action
  async update(body) {
    this.isLoading = true
    await promotionService.update(body).finally(() => {
      this.isLoading = false
    })
  }

  @action
  async filter(params: any) {
    this.isLoading = true
    this.pagedData = await promotionService.filter(params).finally(() => (this.isLoading = false))
  }

  @action
  async getById(id) {
    this.editPromotion = await promotionService.getById(id)
    return this.editPromotion
  }

  @action
  async createPromotion() {
    this.editPromotion = {}
  }

  @action
  async activateOrDeactivate(id) {
    await promotionService.activateOrDeactivate(id)
  }

  @action
  async exportPromotionHistoryUsed(params) {
    this.isLoading = true
    await promotionService.exportPromotionHistoryUsed(params)
    this.isLoading = false
  }

  @action
  async publishOrRecall(body) {
    await promotionService.publishOrRecall(body)
  }
}

export default PromotionStore
