import { AuthenticationModel, SwitchProjectModel } from './dto/authenticationModel'
import { AuthenticationResultModel } from './dto/authenticationResultModel'
import http from '../httpService'

class TokenAuthService {
  public async authenticate(authenticationInput: AuthenticationModel): Promise<AuthenticationResultModel> {
    const result = await http.post('api/TokenAuth/Authenticate', authenticationInput)
    return result.data.result
  }

  public async switchProject(targetProjectId): Promise<AuthenticationResultModel> {
    const result = await http.post('api/TokenAuth/SwitchProjectAuthenticate', null, { params: { targetProjectId } })
    return SwitchProjectModel.assign(result.data.result)
  }
}

export default new TokenAuthService()
