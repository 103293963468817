import Loading from './../Loading/index'
import { Suspense } from 'react'

// eslint-disable-next-line react/display-name
const LoadableComponent = (Component: any) => () =>
  (
    <Suspense fallback={<Loading />}>
      <Component></Component>
    </Suspense>
  )

export default LoadableComponent
