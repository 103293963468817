import withSplashScreen from '@components/Layout/SplashScreen'
import { inject } from 'mobx-react'
import * as React from 'react'

import Router from './components/Layout/Router'
import SignalRAspNetCoreHelper from './lib/signalRAspNetCoreHelper'
import SessionStore from './stores/sessionStore'
import Stores from './stores/storeIdentifier'

export interface IAppProps {
  sessionStore?: SessionStore
}

@inject(Stores.SessionStore)
class App extends React.Component<IAppProps> {
  state = { isLoading: true, needLoginStep: undefined }

  async componentDidMount() {
    await this.props.sessionStore!.getCurrentLoginInformations()

    if (!this.props.sessionStore!.currentLogin.user && window.location.pathname !== '/account/login') {
      window.location.href = '/account/login'
    }
    if (
      !!this.props.sessionStore!.currentLogin.user &&
      this.props.sessionStore!.currentLogin.application.features['SignalR']
    ) {
      SignalRAspNetCoreHelper.initSignalR()
    }

    this.setState({ isLoading: false })
  }

  public render() {
    return <Router />
  }
}

export default withSplashScreen(App)
