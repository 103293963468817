import http from '../httpService'
import { L, LNotification } from '../../lib/abpUtility'
import { notifyError, notifySuccess, prepareLinkQueryString } from '../../lib/helper'
import { FileModel } from '../../models/File'
import { AppConfiguration } from '@lib/appconst'

class FileService {
  public async upload(moduleName, uniqueId, files: any) {
    const data = new FormData()
    ;(files || []).forEach((file, index) => {
      data.append('file' + index, file)
    })

    // data.append('file', files[0])
    const result = await http.post(`api/Documents/Upload${moduleName}`, data, {
      headers: {
        'content-type': 'multipart/form-data'
      },
      params: { uniqueId }
    })
    return result.data.result
  }

  public async get(uniqueId: string): Promise<FileModel[]> {
    if (!uniqueId) {
      notifyError(L('ERROR'), L('ENTITY_NOT_FOUND'))
    }

    const res = await http.get('api/services/app/Documents/GetDocuments', { params: { uniqueId } })
    const result = res.data.result
    return FileModel.assigns(result || [])
  }

  public async delete(id): Promise<any> {
    if (!id) {
      notifyError(L('ERROR'), L('ENTITY_NOT_FOUND'))
    }

    const res = await http.delete('api/services/app/Documents/Delete', { params: { id } })
    notifySuccess(LNotification('SUCCESS'), LNotification('DELETE_SUCCESSFULLY'))
    return res.data.result
  }

  public async downloadTempFile({ fileName, fileType, fileToken }) {
    if (!fileName) {
      notifyError(L('ERROR'), L('FILE_NOT_FOUND'))
    }
    if (!fileToken) {
      notifyError(L('ERROR'), L('NOTIFICATION_DOWNLOAD_NEED_FILE_TOKEN'))
    }

    const url = prepareLinkQueryString(
      { fileName, fileType, fileToken },
      AppConfiguration.remoteServiceBaseUrl + 'api/File/DownloadTempFile'
    )

    window.open(url, '_blank')
  }
}

export default new FileService()
