// import { LNotification } from '@lib/abpUtility'
// import { notifySuccess } from '@lib/helper'
import type { PagedResultDto } from '@services/dto/pagedResultDto'
import truckHiringService from '@services/truckHiring/truckHiringService'
import { action, makeObservable, observable } from 'mobx'

class TruckHiringStore {
  @observable isLoading!: boolean
  @observable dismantlingRequests: PagedResultDto<any> = { totalCount: 0, items: [] }
  @observable editDismantlingRequests: any = {}
  @observable dismantlingStatus: any[] = []
  @observable customerOptions: any[] = []

  constructor() {
    makeObservable(this)
  }

  // @action async getTruckHiringRequest () {
  //   if (this.dismantlingStatus[0]) {return } else {
  //     this.dismantlingStatus = await truckHiringService.getTruckHiringRequest()
  //   }
  // }

  // @action async getCustomerOptions (keyword) {

  //   let res = await truckHiringService.getCustomerOptions({keyword, skipCount: 0, maxResultCount: 10})
  //   this.customerOptions = res.items
  // }
  @action async getAll(params) {
    this.isLoading = true
    const result = await truckHiringService.getAll(params).finally(() => (this.isLoading = false))
    this.dismantlingRequests = result
  }

  // @action async createDismantling (body) {
  //   this.isLoading = true
  //   let beforeDismantleData = body.dismantlingPhotoBefore;
  //   delete body.dismantlingPhotoBefore
  //   let result = await truckHiringService.createDismantling(body).finally(() => (this.isLoading = false))
  //   await truckServices.uploadPhoto(beforeDismantleData, 'BeforeDismantle', result.uniqueId)
  //   notifySuccess(LNotification('SUCCESS'), LNotification('CREATE_SUCCESSFULLY'))
  //   this.editDismantlingRequests = result
  // }
  // @action async updateDismantling (body) {
  //   this.isLoading = true

  //   let beforeDismantleData = body.dismantlingPhotoBefore;
  //   let afterDismantleData = body.dismantlingPhotoAfter;
  //   delete body.dismantlingPhotoBefore
  //   delete body.dismantlingPhotoAfter
  //   let result = await truckHiringService.updateDismantling(body).finally(() => (this.isLoading = false))
  //   await Promise.all([
  //     truckServices.uploadPhoto(beforeDismantleData, 'BeforeDismantle', result.uniqueId),
  //     truckServices.uploadPhoto(afterDismantleData, 'AfterDismantle', result.uniqueId)
  //   ])

  //   notifySuccess(LNotification('SUCCESS'), LNotification('UPDATE_SUCCESSFULLY'))
  //   this.editDismantlingRequests = result
  // }
  // @action async getDoccument(uniqueId) {
  //   this.isLoading = true
  //   let result = await truckHiringService.getDocument(uniqueId).finally(() => (this.isLoading = false))
  //   return result
  // }

  // @action async get(id) {
  //   this.isLoading = true
  //   let result = await truckHiringService.get(id).finally(() => (this.isLoading = false))
  //   this.editDismantlingRequests = result
  //   if (result.customerUser) this.customerOptions.push(result.customerUser)
  // }

  // @action async deactivate(id) {
  //   await truckHiringService.deactivate(id)
  //   await this.getAll({})
  // }
}

export default TruckHiringStore
