import type { PagedResultDto } from '@services/dto/pagedResultDto'
import http from '../httpService'
import dayjs from 'src/lib/dayjs'
import { downloadFile } from '@lib/helperFile'
import { notifySuccess } from '@lib/helper'
import { LNotification } from '@lib/abpUtility'
import { ExpenseMandateModel } from '@models/finance/expenseMandateModel'
import { ExpenseMandateFormDto } from '@services/finance/dto/expenseMandateFormDto'
import AppConsts from '@lib/appconst'
const { cashAdvanceTransactionTypes } = AppConsts

class ExpenseMandateService {
  public async create(body: any) {
    const data = ExpenseMandateFormDto.assign(body)
    const result = await http.post('/api/services/app/CashAdvance/AddWithDraw', data)
    notifySuccess(LNotification('SUCCESS'), LNotification('ITEM_CREATE_SUCCEED'))
    return result.data.result
  }

  public async filter(filters: any): Promise<PagedResultDto<ExpenseMandateModel>> {
    const params = this.proccessParams(filters)
    params.transactionType = cashAdvanceTransactionTypes.expenseMandate

    const res = await http.get('/api/services/app/CashAdvance/GetCashAdvanceReceipts', { params })
    const result = res.data.result
    result.items = ExpenseMandateModel.assigns(result.items)
    return result
  }

  public async delete(params) {
    return http.delete('/api/services/app/ExpenseMandate/Remove', { params })
  }

  public async downloadVouchers(filters) {
    const params = this.proccessParams(filters)

    const response = await http.get('api/ExpenseMandates/ExportFeeVoucher', { responseType: 'blob', params })
    downloadFile(response.data, 'fee-receipts.xlsx')
  }

  public async exportExpenseMandate(filters: any): Promise<any> {
    const params = this.proccessParams(filters)
    params.transactionType = cashAdvanceTransactionTypes.expenseMandate

    const res = await http.get('api/Export/ExportExpenseMandateRequests', { params, responseType: 'blob' })
    downloadFile(res.data, 'expense-mandate-export.xlsx')
  }
  public async exportCashAdvanceReceipts(filters: any): Promise<any> {
    const params = this.proccessParams(filters)
    params.transactionType = cashAdvanceTransactionTypes.expenseMandate

    const res = await http.get('api/Export/ExportCashAdvanceReceipts', { params, responseType: 'blob' })
    downloadFile(res.data, 'expense-mandate-export.xlsx')
  }

  private proccessParams(params) {
    const filters = { ...(params || {}) }

    const [fromDate, toDate] = params.dateFromTo || []
    filters.fromDate = fromDate ? dayjs(fromDate).startOf('day').toJSON() : null
    filters.toDate = toDate ? dayjs(toDate).endOf('day').toJSON() : null
    delete filters.dateFromTo

    return filters
  }
}

export default new ExpenseMandateService()
