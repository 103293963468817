import applicationSettingService from '@services/applicationSetting/applicationSettingService'
import { action, makeObservable, observable } from 'mobx'

class ApplicationSettingStore {
  @observable isLoading!: boolean
  @observable editSetting!: any

  constructor() {
    makeObservable(this)
  }

  @action
  async getAll() {
    this.isLoading = true
    this.editSetting = await applicationSettingService.getAll().finally(() => (this.isLoading = false))
  }

  @action
  async getResetSetting() {
    this.isLoading = true
    return await applicationSettingService.getResetSetting().finally(() => (this.isLoading = false))
  }
  @action
  async updateSetting(body) {
    this.isLoading = true
    return await applicationSettingService.updateSetting(body).finally(() => (this.isLoading = false))
  }
}

export default ApplicationSettingStore
