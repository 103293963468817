import { GetCurrentLoginInformations } from './dto/getCurrentLoginInformations'
import http from '../httpService'
import { AppSettingConfiguration } from '@models/global'

declare let abp: any

class SessionService {
  public async getCurrentLoginInformations(): Promise<GetCurrentLoginInformations> {
    const result = await http.get('api/services/app/Session/GetCurrentLoginInformations', {
      headers: {
        'Abp.TenantId': abp.multiTenancy.getTenantIdCookie()
      }
    })
    return result.data.result
  }

  public async getWebConfiguration(): Promise<AppSettingConfiguration> {
    const result = await http.get('api/services/app/Configuration/GetWebConfiguration')
    return AppSettingConfiguration.assign(result.data.result)
  }

  sleep(milliseconds) {
    const date = Date.now()
    let currentDate
    do {
      currentDate = Date.now()
    } while (currentDate - date < milliseconds)
  }
}

export default new SessionService()
