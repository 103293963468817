import { LNotification } from '@lib/abpUtility'
import { notifySuccess } from '@lib/helper'
import { RemovalModel } from '@models/removal/removalModel'

import type { PagedResultDto } from '@services/dto/pagedResultDto'
import removalService from '@services/removal/removalService'
import truckServices from '@services/truck/truckServices'
import { action, makeObservable, observable } from 'mobx'

class RemovalRequestStore {
  @observable isLoading!: boolean
  @observable pagedData: PagedResultDto<RemovalModel> = { totalCount: 0, items: [] }
  @observable editRemoval: any = {}
  @observable removalRequestSetting!: any
  @observable customerOptions: any[] = []
  @observable partnerOptions: any[] = []
  @observable listStatus: any[] = []
  @observable removalRequestStatus: any[] = []
  @observable promotions: any[] = []
  @observable distributionHistory!: PagedResultDto<any>

  constructor() {
    makeObservable(this)
    this.initEditRemoval()
  }

  @action async initEditRemoval() {
    this.editRemoval = { truckTypeId: 1, productId: 1, estQuantity: 1, paymentMethod: 1 }
  }

  @action async getDistributionHistory(params: any) {
    this.isLoading = true
    this.distributionHistory = await removalService
      .getDistributionHistory(params)
      .finally(() => (this.isLoading = false))
  }
  @action async getCancelOptionsRequest() {
    this.isLoading = true
    return await removalService.getCancelOptionsRequest().finally(() => (this.isLoading = false))
  }

  @action async getCancelOptionsDetail() {
    this.isLoading = true
    return await removalService.getCancelOptionsDetail().finally(() => (this.isLoading = false))
  }

  @action async getListStatus() {
    this.isLoading = true
    this.listStatus = await removalService.getListStatus().finally(() => (this.isLoading = false))
  }

  @action async getRemovalStatus(id) {
    this.isLoading = true
    this.removalRequestStatus = await removalService.getRemovalStatus(id).finally(() => (this.isLoading = false))
  }

  @action async getRequestSetting() {
    this.isLoading = true
    if (this.removalRequestSetting) {
      return
    } else {
      this.removalRequestSetting = await removalService.getRequestSetting().finally(() => (this.isLoading = false))
    }
  }

  @action async getRequestSettingV2(params) {
    this.isLoading = true

    this.removalRequestSetting = await removalService
      .getRequestSettingV2(params)
      .finally(() => (this.isLoading = false))
  }

  @action async getCustomerOptions(keyword) {
    this.isLoading = true

    const res = await removalService
      .getCustomerOptions({ keyword, skipCount: 0, maxResultCount: 10 })
      .finally(() => (this.isLoading = false))
    this.customerOptions = res.items
  }
  @action async getPartnerOptions(keyword, truckTypeIds) {
    this.isLoading = true
    const moduleId = 17
    const res = await removalService
      .getPartnerOptions({ keyword, moduleId, truckTypeIds, skipCount: 0, maxResultCount: 10 })
      .finally(() => (this.isLoading = false))
    this.partnerOptions = res.items
  }
  @action async getAll(params) {
    this.isLoading = true
    const result = await removalService.getAll(params).finally(() => (this.isLoading = false))
    this.pagedData = result
  }

  @action async createRemoval(body) {
    this.isLoading = true
    const removalPhotoData = body.removalPhoto
    delete body.removalPhoto
    const result = await removalService.createRemoval(body).finally(() => (this.isLoading = false))
    if (removalPhotoData) {
      await truckServices.uploadPhoto(removalPhotoData, 'Removal', result.uniqueId)
    }
    notifySuccess(LNotification('SUCCESS'), LNotification('CREATE_SUCCESSFULLY'))
    this.editRemoval = result
  }
  @action async updateRemoval(body) {
    this.isLoading = true
    await removalService.updateStatusRemoval(body).finally(() => (this.isLoading = false))
    notifySuccess(LNotification('SUCCESS'), LNotification('UPDATE_SUCCESSFULLY'))
    // this.editRemoval = result
  }
  @action async getDoccument(uniqueId) {
    this.isLoading = true
    const result = await removalService.getDocument(uniqueId).finally(() => (this.isLoading = false))
    return result
  }

  @action async get(id) {
    this.isLoading = true
    const result = await removalService.get(id).finally(() => (this.isLoading = false))

    this.editRemoval = result
    if (result.customerUser && !this.customerOptions[0]) this.customerOptions.push(result.customerUser)
    if (result.truckUserInformation && !this.partnerOptions[0]) this.partnerOptions.push(result.truckUserInformation)
  }

  @action async deactivate(id) {
    await removalService.deactivate(id)
    await this.getAll({})
  }

  @action async getCustomerPromotion(userId) {
    this.promotions = await removalService.getCustomerPromotion(userId)
    return this.promotions
  }

  @action
  async exportRemovalRequest(params) {
    this.isLoading = true
    await removalService.exportRemovalRequest(params)
    this.isLoading = false
  }
}

export default RemovalRequestStore
