import holidayService from '@services/master-data/holidayService'
import { action, makeObservable, observable } from 'mobx'

import type { PagedResultDto } from '../../services/dto/pagedResultDto'

class HolidayStore {
  @observable isLoading!: boolean
  @observable editTransportationCost!: any
  @observable holidays!: PagedResultDto<any>

  constructor() {
    this.holidays = { items: [], totalCount: 0 }
    makeObservable(this)
  }

  @action
  async create(body) {
    this.isLoading = true
    await holidayService.create(body).finally(() => {
      this.isLoading = false
    })
  }

  @action
  async update(body) {
    this.isLoading = true
    await holidayService.update(body).finally(() => {
      this.isLoading = false
    })
  }

  @action
  async getAll(params: any) {
    this.isLoading = true
    this.holidays = await holidayService.filter(params).finally(() => (this.isLoading = false))
  }

  @action
  async getById(id) {
    this.editTransportationCost = await holidayService.getById(id)
  }

  @action
  async activateOrDeactivate(id, isActive) {
    await holidayService.activateOrDeactivate(id, isActive)
  }
}

export default HolidayStore
