export class RemovalSettingModel {
  commissionPercent = 0
  isHasCompletedRequest = false
  maxSurcharge = 0

  product?: any
  products?: any
  serviceFees?: any
  truckTypes?: any

  public static assign(obj) {
    if (!obj) return undefined

    const newObj = Object.assign(new RemovalSettingModel(), obj)

    if (!newObj.serviceFees || !newObj.serviceFees.length) {
      const serviceFees: any = []
      newObj.products.forEach((product) => {
        product.productServiceFees.forEach((serviceFee) => {
          serviceFee.truckTypeServiceFees.forEach((truckType) => {
            serviceFees.push({
              serviceFeeId: serviceFee.id,
              truckTypeId: truckType.truckTypeId,
              price: serviceFee.serviceFeeAmount
            })
          })
        })
      })
    }
    return newObj
  }

  public static assigns<T>(objs) {
    const results: RemovalSettingModel[] = []
    objs.forEach((item) => results.push(this.assign(item)))
    return results
  }
}
