import React from 'react'
import { saveAs } from 'file-saver'

const AppAds = () => {
  React.useEffect(() => {
    const blob = new Blob(
      ['facebook.com,798973804611189, DIRECT,c3e20eee3f780d68 \nfacebook.com,586756936274852, DIRECT,c3e20eee3f780d68'],
      {
        type: 'text/plain;charset=utf-8'
      }
    )
    saveAs(blob, 'app-ads.txt')
  }, [])
  return <div></div>
}

export default AppAds
