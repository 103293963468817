import { initMultiLanguageField, mapMultiLanguageField } from '@lib/helper'

export class ReasonRejectModel {
  id?: number
  name?: string
  names?: any[]
  code?: boolean
  description?: string
  isActive?: boolean

  constructor() {
    this.id = undefined
    this.isActive = true
    this.names = initMultiLanguageField()
  }

  public static assign(obj) {
    if (!obj) return undefined

    const newObj = Object.assign(new ReasonRejectModel(), obj)
    newObj.names = mapMultiLanguageField(newObj.names)
    return newObj
  }

  public static assigns<T>(objs) {
    const results: ReasonRejectModel[] = []
    objs.forEach((item) => results.push(this.assign(item)))
    return results
  }
}
