import type { PagedResultDto } from '@services/dto/pagedResultDto'
import * as OutstandingReportModel from '@models/finance/outstandingReportModel'
import { action, makeObservable, observable } from 'mobx'
import outstandingReportService from '@services/finance/outstandingReportService'
import { OptionModel } from '@models/global'

class OutstandingReportStore {
  @observable pagedData!: PagedResultDto<OutstandingReportModel.IOutstandingReport>
  @observable isLoading!: boolean
  @observable outstandingReports!: OutstandingReportModel.IOutstandingReport[]
  @observable editOutstandingReport!: OutstandingReportModel.IOutstandingReport
  @observable listStatus!: OptionModel[]

  constructor() {
    this.pagedData = {
      items: [],
      totalCount: 0
    }

    this.listStatus = []
    makeObservable(this)
  }

  @action
  async create(body) {
    await outstandingReportService.create(body)
  }

  @action
  async update(body) {
    await outstandingReportService.update(body)
  }

  @action
  async updateSortList(body) {
    this.isLoading = true
    await outstandingReportService.updateSortList(body).finally(() => {
      this.isLoading = false
    })
  }

  @action
  async activateOrDeactivate(id, isActive) {
    await outstandingReportService.activateOrDeactivate(id, isActive)
  }

  @action
  async delete(id) {
    //
  }

  @action
  async get(id) {
    const result = await outstandingReportService.get(id)
    this.editOutstandingReport = result
  }

  @action
  async createOutstandingReport() {
    this.editOutstandingReport = new OutstandingReportModel.OutstandingReportModel()
  }

  @action
  async filter(params) {
    this.isLoading = true
    const result = await outstandingReportService.filter(params).finally(() => (this.isLoading = false))
    this.pagedData = result
  }

  @action
  async getAll(params) {
    this.isLoading = true
    params.isActive = true
    this.outstandingReports = await outstandingReportService.getAll(params).finally(() => (this.isLoading = false))
  }

  @action
  async cancelRequest(data) {
    this.isLoading = true
    this.outstandingReports = await outstandingReportService.cancelRequest(data).finally(() => (this.isLoading = false))
  }

  @action
  async export(params) {
    this.isLoading = true
    await outstandingReportService.export(params)
    this.isLoading = false
  }
}

export default OutstandingReportStore
