import { LanguageValue } from '@models/global'
import { initMultiLanguageField } from '@lib/helper'
import { v4 as uuid } from 'uuid'
import { TruckTypeModel } from '@models/master-data/truckTypeModel'

export type chargeTypes = 'TRUCK' | 'LOCATION'
export const chargeTypeMap = {
  '0': 'ADDITIONAL_CHARGE_FEE_TRUCK',
  '1': 'ADDITIONAL_CHARGE_FEE_LOCATION'
}
export const AdditionalChargeTypes = [
  {
    value: 'TRUCK',
    name: 'ADDITIONAL_CHARGE_FEE_TRUCK'
  },
  {
    value: 'LOCATION',
    name: 'ADDITIONAL_CHARGE_FEE_LOCATION'
  }
]

export interface IFeeTypeAdditionalCharge {
  chargeType: string
  truckTypeId?: number
  provinceId?: number
  districtId?: number
  communeId?: number
  addingAmount: number
  isActive: boolean
}

export interface IDisplayAdditionalCharge {
  id: string
  truckType: any
  province: any
  district: any
  commune: any
  truckAmount: number
  locationAmount: number
  totalAmount: number
}

export interface IFeeType {
  code?: string
  name?: string
  names?: LanguageValue[]
  sortNumber?: number
  description?: any
  isActive?: boolean
  id?: number
  nameId?: string
  serviceFeeAmount?: number
  serviceFeeAddingCharges?: IFeeTypeAdditionalCharge[]
}

export class FeeTypeAdditionalCharge implements IFeeTypeAdditionalCharge {
  serviceFeeId: number
  chargeType: string
  chargeTypeName: string
  truckTypeId?: number | undefined
  provinceId?: number | undefined
  districtId?: number | undefined
  communeId?: number | undefined
  addingAmount: number
  isActive: boolean

  constructor(serviceFeeId?) {
    this.serviceFeeId = serviceFeeId
    this.chargeType = 'TRUCK'
    this.truckTypeId = 1
    this.chargeTypeName = ''
    this.addingAmount = 0
    this.isActive = true
  }

  public static assign(obj) {
    if (!obj) return undefined

    const newObj = Object.assign(new FeeTypeAdditionalCharge(), obj)
    newObj.chargeTypeName = chargeTypeMap[obj.chargeType]

    return newObj
  }

  public static assigns<T>(objs) {
    const results: any[] = []
    objs.forEach((item) => results.push(this.assign(item)))
    return results
  }
}

export class FeeTypeModel implements IFeeType {
  code?: string
  name?: string
  names?: LanguageValue[]
  sortNumber?: number
  description?: any
  isActive?: boolean
  id?: number
  nameId?: string
  serviceFeeAmount?: number
  truckTypes?: TruckTypeModel[]
  truckTypeServiceFees?: number[] // Truck type ids
  serviceFeeAddingCharges?: FeeTypeAdditionalCharge[]
  displayAdditionalCharges?: IDisplayAdditionalCharge[]

  constructor() {
    this.id = undefined
    this.sortNumber = 1
    this.isActive = true
    this.names = initMultiLanguageField()
  }

  public static assign(obj) {
    if (!obj) return undefined

    const newObj = Object.assign(new FeeTypeModel(), obj)
    newObj.names = LanguageValue.init(obj.names || [])
    newObj.truckTypes = TruckTypeModel.assigns((obj.truckTypeServiceFees || []).map((item) => item.truckType))
    newObj.truckTypeServiceFees = newObj.truckTypes.map((item) => item.id)

    let addingChargeByTruck = (obj.serviceFeeAddingCharges || []).filter((item) => item.chargeType === 'TRUCK')
    let addingChargeByLocation = (obj.serviceFeeAddingCharges || []).filter((item) => item.chargeType === 'LOCATION')
    const addingChargeFees: IDisplayAdditionalCharge[] = []
    if (addingChargeByTruck.length || addingChargeByLocation.length) {
      addingChargeByTruck = addingChargeByTruck.length
        ? addingChargeByTruck
        : [{ truckType: { name: 'ALL' }, addingAmount: 0 }]
      addingChargeByLocation = addingChargeByLocation.length
        ? addingChargeByLocation
        : [{ province: { name: 'ALL' }, addingAmount: 0 }]
      addingChargeByTruck.forEach((feeByTruck, i) => {
        addingChargeByLocation.forEach((feeByLocation, j) => {
          addingChargeFees.push({
            id: uuid(),
            truckType: feeByTruck.truckType,
            province: feeByLocation.province,
            district: feeByLocation.district,
            commune: feeByLocation.commune,
            truckAmount: feeByTruck.addingAmount,
            locationAmount: feeByLocation.addingAmount,
            totalAmount: feeByTruck.addingAmount + feeByLocation.addingAmount
          })
        })
      })
      newObj.displayAdditionalCharges = addingChargeFees
    }
    return newObj
  }

  public static assigns<T>(objs) {
    const results: any[] = []
    objs.forEach((item) => results.push(this.assign(item)))
    return results
  }
}
