import * as React from 'react'
import { createRoot } from 'react-dom/client'

import App from './App'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'mobx-react'
import Utils from './utils/utils'
import initializeStores from './stores/storeInitializer'
import { unregister } from './registerServiceWorker'
import appDataService from '@services/appDataService'
import 'antd/dist/reset.css'
import './index.css'
import './styles/custom-bootstrap.scss'
import './styles/custom-ant.scss'
import './styles/app.scss'
import { ErrorBoundary } from '@components/ErrorBoundary'
import { ConfigProvider } from 'antd'

Utils.setLocalization()
appDataService.getAppConfiguration().then(async () => {
  const stores = initializeStores()

  const root = createRoot(document.getElementById('root') as HTMLElement)
  root.render(
    <Provider {...stores}>
      <ConfigProvider
        theme={{
          token: {
            // Seed Token
            colorPrimary: '#f5b700',
            colorText: '#1d4449cc',
            colorLinkActive: '#f5b700'
          }
        }}
      >
        <BrowserRouter>
          <ErrorBoundary>
            <App />
          </ErrorBoundary>
        </BrowserRouter>
      </ConfigProvider>
    </Provider>
  )

  unregister()
})
