import { LNotification } from '@lib/abpUtility'
import { notifySuccess } from '@lib/helper'
import dismantlingService from '@services/dismantling/dismantlingService'
import type { PagedResultDto } from '@services/dto/pagedResultDto'
import truckServices from '@services/truck/truckServices'
import { action, makeObservable, observable } from 'mobx'

class DismantlingStore {
  @observable isLoading!: boolean
  @observable dismantlingRequests: PagedResultDto<any> = { totalCount: 0, items: [] }
  @observable editDismantlingRequests: any = {}
  @observable dismantlingStatus: any[] = []
  @observable customerOptions: any[] = []

  constructor() {
    makeObservable(this)
  }

  @action async getDismantlingRequest(id) {
    this.dismantlingStatus = await dismantlingService.getDismantlingRequest(id)
  }

  @action async getDismantlingRequestDefault() {
    this.dismantlingStatus = await dismantlingService.getDismantlingRequestDefault()
  }

  @action async getCustomerOptions(keyword) {
    const res = await dismantlingService.getCustomerOptions({ keyword, skipCount: 0, maxResultCount: 10 })
    this.customerOptions = res.items
  }
  @action async getAll(params) {
    this.isLoading = true
    const result = await dismantlingService.getAll(params).finally(() => (this.isLoading = false))
    this.dismantlingRequests = result
  }

  @action async createDismantling(body) {
    this.isLoading = true
    const beforeDismantleData = body.dismantlingPhotoBefore
    delete body.dismantlingPhotoBefore
    const result = await dismantlingService.createDismantling(body).finally(() => (this.isLoading = false))
    await truckServices.uploadPhoto(beforeDismantleData, 'BeforeDismantle', result.uniqueId)
    notifySuccess(LNotification('SUCCESS'), LNotification('CREATE_SUCCESSFULLY'))
    this.editDismantlingRequests = result
  }
  @action async updateDismantling(body) {
    this.isLoading = true

    const beforeDismantleData = body.dismantlingPhotoBefore
    const afterDismantleData = body.dismantlingPhotoAfter
    delete body.dismantlingPhotoBefore
    delete body.dismantlingPhotoAfter
    const result = await dismantlingService.updateDismantling(body).finally(() => (this.isLoading = false))
    await Promise.all([
      truckServices.uploadPhoto(beforeDismantleData, 'BeforeDismantle', result.uniqueId),
      truckServices.uploadPhoto(afterDismantleData, 'AfterDismantle', result.uniqueId)
    ])

    notifySuccess(LNotification('SUCCESS'), LNotification('UPDATE_SUCCESSFULLY'))
    this.editDismantlingRequests = result
  }
  @action async getDoccument(uniqueId) {
    this.isLoading = true
    const result = await dismantlingService.getDocument(uniqueId).finally(() => (this.isLoading = false))
    return result
  }

  @action async get(id) {
    this.isLoading = true
    const result = await dismantlingService.get(id).finally(() => (this.isLoading = false))
    this.editDismantlingRequests = result
    if (result.customerUser) this.customerOptions.push(result.customerUser)
  }

  @action async deactivate(id) {
    await dismantlingService.deactivate(id)
    await this.getAll({})
  }
}

export default DismantlingStore
