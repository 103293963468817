import { action, makeObservable, observable } from 'mobx'

import type { PagedResultDto } from '../../services/dto/pagedResultDto'
import reasonRejectService from '@services/master-data/reasonRejectService'
import { ReasonRejectModel } from '@models/master-data/reasonRejectModel'

class ReasonRejectStore {
  @observable isLoading!: boolean
  @observable editReasonReject!: any
  @observable pagedData!: PagedResultDto<any>

  @observable editReasonWaiting!: any
  @observable waitingReasonPagedData!: PagedResultDto<any>

  constructor() {
    this.pagedData = { items: [], totalCount: 0 }
    this.waitingReasonPagedData = { items: [], totalCount: 0 }
    makeObservable(this)
  }

  @action
  async create(body) {
    this.isLoading = true
    await reasonRejectService.create(body).finally(() => {
      this.isLoading = false
    })
  }

  @action
  async update(body) {
    this.isLoading = true
    await reasonRejectService.update(body).finally(() => {
      this.isLoading = false
    })
  }

  @action
  async filter(params: any) {
    this.isLoading = true
    this.pagedData = await reasonRejectService.filter(params).finally(() => (this.isLoading = false))
  }

  @action
  async getById(id) {
    this.editReasonReject = await reasonRejectService.getById(id)
  }

  @action
  async createReasonRejectBadge() {
    this.editReasonReject = new ReasonRejectModel()
  }

  @action
  async activateOrDeactivate(body, isActive) {
    await reasonRejectService.activateOrDeactivate(body)
  }

  //----------------------------------------------------------

  @action
  async createWaitingReason(body) {
    this.isLoading = true
    await reasonRejectService.createWaitingReason(body).finally(() => {
      this.isLoading = false
    })
  }

  @action
  async updateWaitingReason(body) {
    this.isLoading = true
    await reasonRejectService.updateWaitingReason(body).finally(() => {
      this.isLoading = false
    })
  }

  @action
  async filterWaitingReason(params: any) {
    this.isLoading = true
    this.waitingReasonPagedData = await reasonRejectService
      .filterWaitingReason(params)
      .finally(() => (this.isLoading = false))
  }

  @action
  async getByIdWaitingReason(id) {
    this.editReasonWaiting = await reasonRejectService.getByIdWaitingReason(id)
  }

  @action
  async createWaitingReasonModal() {
    this.editReasonWaiting = new ReasonRejectModel()
  }

  @action
  async activateOrDeactivateWaitingReason(body, isActive) {
    await reasonRejectService.activateOrDeactivateWaitingReason(body)
  }
}

export default ReasonRejectStore
