import { LNotification } from '@lib/abpUtility'
import { compressImage, notifySuccess } from '@lib/helper'
import { TruckModel } from '@models/truck'
import type { PagedResultDto } from '@services/dto/pagedResultDto'
import http from '@services/httpService'

class TruckService {
  public async getAll(params: any): Promise<PagedResultDto<TruckModel>> {
    const res = await http.get('api/services/app/Truck/GetAll', { params })
    const result = res.data.result
    result.items = TruckModel.assigns(result.items)
    return result
  }
  public async getTruckColor(params?: any): Promise<any> {
    const res = await http.get('api/services/app/Truck/GetTruckColors', { params })
    const result = res.data.result.map((item) => ({ value: item }))
    return result
  }

  public async get(id: number) {
    const res = await http.get('api/services/app/Truck/Get', { params: { id } })
    const result = TruckModel.assign(res.data.result)
    ;(result.truckUserLocation || []).forEach((item) => {
      item.communeId = item.commune?.id || null
    })
    return result
  }
  public async getPartnerOptions(params) {
    const res = await http.get('api/services/app/Partners/GetAll', {
      params: { ...params, skipCount: 0, maxResultCount: 10 }
    })
    const result = res.data.result.items
    return result
  }

  public async createTruck(body) {
    const res = await http.post('api/services/app/Truck/Create', body)
    return res.data.result
  }
  public async updateTruck(body) {
    const dataIdentityCard = new FormData()
    ;(body.truckUserInformation.identityCard || [])
      .filter((item) => !item.id)
      .forEach(async (file) => {
        const blobFile = new Blob([file.originFileObj], { type: file.type })
        dataIdentityCard.append('identityCard', blobFile, file.name)
      })

    const dataTruckCard = new FormData()
    ;(body.truckUserInformation.truckCard || [])
      .filter((item) => !item.id)
      .forEach((file) => {
        const blobFile = new Blob([file])
        dataTruckCard.append('truckCard', blobFile, file.name)
      })
    const dataTruck = new FormData()
    ;(body.truckUserInformation.truck || [])
      .filter((item) => !item.id)
      .forEach((file) => {
        const blobFile = new Blob([file])
        dataTruck.append('truck', blobFile, file.name)
      })
    const dataSafetyCertification = new FormData()
    ;(body.truckUserInformation.safetyCertification || [])
      .filter((item) => !item.id)
      .forEach((file) => {
        const blobFile = new Blob([file])
        dataSafetyCertification.append('safetyCertification', blobFile, file.name)
      })
    const dataInsurance = new FormData()
    ;(body.truckUserInformation.insurance || [])
      .filter((item) => !item.id)
      .forEach((file) => {
        const blobFile = new Blob([file])
        dataInsurance.append('insurance', blobFile, file.name)
      })

    delete body.truckUserInformation['identityCard']
    delete body.truckUserInformation['truckCard']
    delete body.truckUserInformation['truck']
    delete body.truckUserInformation['safetyCertification']
    delete body.truckUserInformation['insurance']

    const res = await http.put('api/services/app/Truck/Update', body)
    const uniqueId = res.data.result.uniqueId
    if (dataIdentityCard.getAll('identityCard').length) {
      await http.post(`api/Documents/UploadIdentityCards`, dataIdentityCard, {
        headers: {
          'content-type': 'multipart/form-data'
        },
        params: { uniqueId }
      })
    }
    if (dataTruckCard.getAll('truckCard').length) {
      await http.post(`/api/Documents/UploadTruckCards`, dataTruckCard, {
        headers: {
          'content-type': 'multipart/form-data'
        },
        params: { uniqueId }
      })
    }
    if (dataTruck.getAll('truck').length) {
      await http.post(`/api/Documents/UploadTrucks`, dataTruck, {
        headers: {
          'content-type': 'multipart/form-data'
        },
        params: { uniqueId }
      })
    }
    if (dataSafetyCertification.getAll('safetyCertification').length) {
      await http.post(`/api/Documents/UploadSafetyCertifications`, dataSafetyCertification, {
        headers: {
          'content-type': 'multipart/form-data'
        },
        params: { uniqueId }
      })
    }
    if (dataInsurance.getAll('insurance').length) {
      await http.post(`api/Documents/UploadInsurances`, dataInsurance, {
        headers: {
          'content-type': 'multipart/form-data'
        },
        params: { uniqueId }
      })
    }
    notifySuccess(LNotification('SUCCESS'), LNotification('UPDATE_SUCCESSFULLY'))
    return res.data.result
  }

  public async getDocument(uniqueId) {
    const res = await http.get('/api/services/app/Documents/GetDocuments', { params: { uniqueId } })
    const result = res.data.result
    return result
  }
  public async getIcon(uniqueId) {
    const res = await http.get('api/Documents/UploadTruckIcon', { params: { uniqueId } })
    const result = res.data.result
    return result
  }
  public async deactivated(id) {
    await http.delete('api/services/app/Truck/Delete', { params: { id } })
  }
  public async active(id, isActive) {
    await http.post('api/services/app/Truck/Active', { isActive: isActive, id })
  }

  public async uploadPhoto(fileList: any[], moduleName: string, uniqueId) {
    if (!fileList || !fileList.length) {
      return
    }
    const data = new FormData()
    const compressImages = await Promise.all(
      fileList
        .filter((item) => !item.id)
        .map(async (file) => {
          const compressFile = await compressImage(file.originFileObj, 1024)
          return { file: compressFile, name: file.name } as any
        })
    )
    compressImages.forEach((file) => data.append(moduleName, file.file, file.name))
    if (data.getAll(moduleName).length) {
      await http.post(`api/Documents/Upload${moduleName}s`, data, {
        headers: {
          'content-type': 'multipart/form-data'
        },
        params: { uniqueId }
      })
    }
  }
}

export default new TruckService()
