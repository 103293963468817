import { compressImage } from '@lib/helper'
import partnerService from '@services/member/partner/partnerService'
import truckServices from '@services/truck/truckServices'
import { action, makeObservable, observable } from 'mobx'

import type { PagedResultDto } from '../../../services/dto/pagedResultDto'

class PartnerStore {
  @observable isLoading!: boolean
  @observable partners!: PagedResultDto<any>
  @observable partnerTrucks!: PagedResultDto<any>
  @observable editPartner!: any
  @observable partnerProjectRoles: any = []
  @observable warnings: any[] = []
  @observable achievements: any[] = []

  constructor() {
    this.partners = { items: [], totalCount: 0 }
    makeObservable(this)
  }
  @action async getAchievements(partnerId) {
    const res = await partnerService.getPartnerBadge(partnerId)
    this.achievements = res
  }
  @action
  async create(body: any) {
    this.isLoading = true
    const identityCardData = body.truckUserInformation?.identityCard
    const truckCardData = body.truckUserInformation?.truckCard
    const truckData = body.truckUserInformation?.truck
    const safetyCertificationData = body.truckUserInformation?.safetyCertification
    const insuranceData = body.truckUserInformation?.insurance
    delete body.truckUserInformation['identityCard']
    delete body.truckUserInformation['truckCard']
    delete body.truckUserInformation['truck']
    delete body.truckUserInformation['safetyCertification']
    delete body.truckUserInformation['insurance']
    this.editPartner = await partnerService.create({ ...body, gender: body.gender === 'null' ? null : body.gender })
    const { truckUserInformation } = this.editPartner
    if (!truckUserInformation.uniqueId) {
      this.isLoading = false
      return
    }
    await Promise.all([
      truckServices.uploadPhoto(identityCardData, 'IdentityCard', truckUserInformation.uniqueId),
      truckServices.uploadPhoto(truckCardData, 'TruckCard', truckUserInformation.uniqueId),
      truckServices.uploadPhoto(truckData, 'Truck', truckUserInformation.uniqueId),
      truckServices.uploadPhoto(safetyCertificationData, 'SafetyCertification', truckUserInformation.uniqueId),
      truckServices.uploadPhoto(insuranceData, 'Insurance', truckUserInformation.uniqueId)
    ]).finally(() => (this.isLoading = false))
  }

  @action async getStaticInformation() {
    this.isLoading = true
    const result = await partnerService.getStaticInformation().finally(() => (this.isLoading = false))
    this.editPartner = Object.assign(this.editPartner, result)
  }

  @action async getWarning() {
    this.isLoading = true
    this.warnings = await partnerService.getWarning().finally(() => (this.isLoading = false))
  }

  @action
  async createStaff() {
    this.editPartner = {
      userName: '',
      name: '',
      surname: '',
      displayName: '',
      emailAddress: '',
      isActive: 'true',
      roleNames: [],
      password: '',
      id: 0
    }
  }

  @action
  async update(body: any) {
    this.isLoading = true

    await partnerService.update(body).finally(() => (this.isLoading = false))
  }

  @action
  async delete(id: number) {
    // await staffService.delete(id)
    // this.staffs.items = this.staffs.items.filter((x) => x.id !== id)
  }

  @action
  async activateOrDeactivate(id: number, isActive) {
    await partnerService.activateOrDeactivate(id, isActive)
  }

  @action
  async get(id: number) {
    const result = await partnerService.get(id)

    const resultStatic = await partnerService.getStaticInformation().finally(() => (this.isLoading = false))
    this.editPartner = Object.assign(result, resultStatic)
    await this.getPartnerPhotos(result.uniqueId)
  }

  @action
  async getPartnerPhotos(uniqueId: string) {
    if (uniqueId) {
      const photos: any[] = await partnerService.getPartnerPhoto(uniqueId)
      const partnerPhoto = photos.map((photo) => {
        return {
          ...photo,
          uid: photo.guid,
          name: photo.originalFileName,
          url: photo.fileUrl
        }
      })
      this.editPartner = { ...this.editPartner, partnerPhoto }
    }
  }

  @action
  async getAll(params: any) {
    this.isLoading = true
    const result = await partnerService.getAll(params).finally(() => (this.isLoading = false))
    this.partners = result
  }

  @action
  async getPartnerStatistic(params: any) {
    this.isLoading = true
    const result = await partnerService.getPartnerStatistic(params).finally(() => (this.isLoading = false))
    this.partnerTrucks = result
  }

  @action
  async exportPartner(params) {
    this.isLoading = true
    await partnerService.exportPartner(params)
    this.isLoading = false
  }

  @action
  async exportPartnerStatistic(params) {
    this.isLoading = true
    await partnerService.exportPartnerStatistic(params)
    this.isLoading = false
  }

  @action
  async SwitchToCustomerUser(id) {
    this.isLoading = true
    await partnerService.SwitchToCustomerUser(id)
    this.isLoading = false
  }

  @action
  async uploadProfileImage(module, file) {
    if (!module || !file?.originFileObj) {
      return
    }
    const compressFile = await compressImage(file.originFileObj, 1024)
    await partnerService.uploadPhoto([compressFile], module, this.editPartner.uniqueId)
  }
}

export default PartnerStore
