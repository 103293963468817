import { action, makeObservable, observable } from 'mobx'

import type { PagedResultDto } from '../../services/dto/pagedResultDto'
import campaignService from '@services/growth/campaignService'
import { moduleFile } from '@lib/appconst'
import fileService from '@services/common/fileService'
import { AwardRecipientModel } from '@models/growth/campaignModel'

class CampaignStore {
  @observable isLoading!: boolean
  @observable editCampaign!: any
  @observable pagedData!: PagedResultDto<any>
  @observable campaignAwardRecipients: PagedResultDto<AwardRecipientModel> = { items: [], totalCount: 0 }
  constructor() {
    this.pagedData = { items: [], totalCount: 0 }
    makeObservable(this)
  }

  @action
  async create(body, files?) {
    this.isLoading = true
    this.editCampaign = await campaignService.create(body).then((res: any) => {
      this.isLoading = !!res && !!(files && files.length)
      return res
    })
    if (!this.editCampaign) {
      this.isLoading = false
      return
    }
    // Upload file if create success
    const { uniqueId } = this.editCampaign
    if (files?.length && uniqueId) {
      await fileService.upload(moduleFile.campaign, uniqueId, files).finally(() => {
        this.isLoading = false
      })
    }
  }
  @action
  async getHistory(params: any) {
    this.isLoading = true
    this.campaignAwardRecipients = await campaignService
      .getRedemptionHistory(params)
      .finally(() => (this.isLoading = false))
  }

  @action
  async update(body, files?) {
    this.isLoading = true
    this.editCampaign = await campaignService.update(body).finally(async () => {
      this.isLoading = !!(files && files.length)
    })
    const { uniqueId } = this.editCampaign
    if (files?.length && uniqueId) {
      await fileService.upload(moduleFile.campaign, uniqueId, files).finally(() => {
        this.isLoading = false
      })
    }
  }

  @action
  async filter(params: any) {
    this.isLoading = true
    this.pagedData = await campaignService.filter(params).finally(() => (this.isLoading = false))
  }

  @action
  async getById(id) {
    this.editCampaign = await campaignService.getById(id)
    return this.editCampaign
  }

  @action
  async createCampaign() {
    this.editCampaign = {}
  }

  @action
  async activateOrDeactivate(body) {
    await campaignService.activateOrDeactivate(body)
  }

  @action
  async publishOrRecall(body) {
    await campaignService.publishOrRecall(body)
  }

  @action
  async sendNotify(body) {
    await campaignService.sendNotify(body)
  }

  @action
  async exportCampaignRewarded(params) {
    this.isLoading = true
    await campaignService.exportRedemptionHistory(params)
    this.isLoading = false
  }
}

export default CampaignStore
