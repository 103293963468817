import type { PagedResultDto } from '../dto/pagedResultDto'
import http from '../httpService'
import { notifyError, notifySuccess } from '@lib/helper'
import { L, LNotification } from '@lib/abpUtility'
import { ReasonRejectModel } from '@models/master-data/reasonRejectModel'

class ReasonRejectService {
  public async create(body: any) {
    const res = await http.post('api/services/app/CancelReason/Create', body)
    notifySuccess(LNotification('SUCCESS'), LNotification(L('SAVE_SUCCESSFULLY')))
    return res.data.result
  }

  public async filter(params: any): Promise<PagedResultDto<any>> {
    const res = await http.get('api/services/app/CancelReason/GetAll', { params })
    const result = res.data.result
    result.items = result.items || []
    return result
  }

  public async update(body: any) {
    const res = await http.put('api/services/app/CancelReason/Update', body)
    notifySuccess(LNotification('SUCCESS'), LNotification(L('SAVE_SUCCESSFULLY')))
    return res.data.result
  }

  public async getById(id): Promise<any> {
    if (!id) {
      notifyError(L('ERROR'), L('ENTITY_NOT_FOUND'))
    }
    const result = await http.get('api/services/app/CancelReason/GetForEdit', { params: { id } })
    return ReasonRejectModel.assign(result.data.result)
  }

  public async activateOrDeactivate(body) {
    const response = await http.post('api/services/app/CancelReason/Active', body)
    notifySuccess(LNotification('SUCCESS'), LNotification('SAVING_SUCCESSFULLY'))
    return response.data
  }

  // ---------------------

  public async createWaitingReason(body: any) {
    const res = await http.post('api/services/app/RequestWaitReason/Create', body)
    notifySuccess(LNotification('SUCCESS'), LNotification(L('SAVE_SUCCESSFULLY')))
    return res.data.result
  }

  public async filterWaitingReason(params: any): Promise<PagedResultDto<any>> {
    const res = await http.get('api/services/app/RequestWaitReason/GetAll', { params })
    const result = res.data.result
    result.items = result.items || []
    return result
  }

  public async updateWaitingReason(body: any) {
    const res = await http.put('api/services/app/RequestWaitReason/Update', body)
    notifySuccess(LNotification('SUCCESS'), LNotification(L('SAVE_SUCCESSFULLY')))
    return res.data.result
  }

  public async getByIdWaitingReason(id): Promise<any> {
    if (!id) {
      notifyError(L('ERROR'), L('ENTITY_NOT_FOUND'))
    }
    const result = await http.get('api/services/app/RequestWaitReason/GetForEdit', { params: { id } })
    return ReasonRejectModel.assign(result.data.result)
  }

  public async activateOrDeactivateWaitingReason(body) {
    const response = await http.post('api/services/app/RequestWaitReason/Active', body)
    notifySuccess(LNotification('SUCCESS'), LNotification('SAVING_SUCCESSFULLY'))
    return response.data
  }
}

export default new ReasonRejectService()
