import { mapActiveStatus } from '@lib/helper'
import { Status } from '@models/global'
import { TruckBrandModel } from '@models/master-data/truckBrandModel'
import { TruckTypeModel } from '@models/master-data/truckTypeModel'
import { UserModel } from '@models/user/IUserModel'

export class TruckModel {
  id: number
  name: string
  user?: UserModel
  numberPlates?: number
  truckBrand?: TruckBrandModel
  isReceiveRequest: boolean
  isConfirmed: boolean
  trunkSize = ''
  cbm = ''
  parkingPlaces = ''
  registerServices = ''
  workingTimes: string[] = []
  status?: Status

  constructor() {
    this.id = 0
    this.name = ''
    this.isReceiveRequest = false
    this.isConfirmed = false
  }

  public static assign(obj) {
    if (!obj) return undefined

    const newObj = Object.assign(new TruckModel(), obj)
    newObj.id = obj.id
    newObj.name = obj.name
    newObj.user = UserModel.assign(obj.user)
    newObj.truckBrand = TruckBrandModel.assign(obj.truckBrand)
    newObj.workingTimes = (obj.workingTimes ?? '').length ? (obj.workingTimes ?? '').split(',') : []
    newObj.status = mapActiveStatus(obj.isActive)
    return newObj
  }

  public static assigns<T>(objs) {
    const results: any[] = []
    objs.forEach((item) => results.push(this.assign(item)))
    return results
  }
}

export class TruckDetailModel {
  id: number
  name: string
  user?: UserModel
  numberPlates?: number
  truckBrand?: TruckBrandModel
  truckType?: TruckTypeModel
  isReceiveRequest: boolean
  isConfirmed: boolean
  status?: Status
  uniqueId?: string
  truckUserLocation?: any
  truckUserInformation?: any

  constructor() {
    this.id = 0
    this.name = ''
    this.isReceiveRequest = false
    this.isConfirmed = false
    this.truckUserInformation = {}
  }

  public static assign(obj) {
    if (!obj) return undefined

    const newObj = Object.assign(new TruckModel(), obj)
    newObj.id = obj.id
    newObj.name = obj.name
    newObj.user = UserModel.assign(obj.user)
    newObj.truckBrand = TruckBrandModel.assign(obj.truckBrand)
    newObj.status = mapActiveStatus(obj.isActive)

    return newObj
  }

  public static assigns<T>(objs) {
    const results: any[] = []
    objs.forEach((item) => results.push(this.assign(item)))
    return results
  }
}
