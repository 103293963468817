import http from '../httpService'

class ApplicationSettingService {
  public async getAll() {
    const res = await http.get('api/services/app/HostSettings/GetAllSettings')
    const result = res.data.result
    return result
  }

  public async getResetSetting() {
    const res = await http.get('api/services/app/HostSettings/GetAllResetSettings')
    const result = res.data.result
    return result
  }

  public async updateSetting(body: any) {
    const res = await http.put('api/services/app/HostSettings/UpdateAllSettings', body)
    const result = res.data.result
    return result
  }
}

export default new ApplicationSettingService()
