import { L } from '@lib/abpUtility'
import { downloadFile } from '@lib/helperFile'
import http from '@services/httpService'
import dayjs from 'src/lib/dayjs'
import { v4 as uuid } from 'uuid'

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms))
class DashboardService {
  public async getData(params: any) {
    const result = []
    await sleep(1000)
    return result
  }
  public async getTotalRevenueData(params: any): Promise<any> {
    params = this.proccessFromToParams(params)
    const res = await http.get('api/services/app/StatisticAppServices/GetRevenueByMonth', { params })
    return this.mapMonthToString(res.data.result, (monthData) => ({
      revenue: monthData.TotalRevenueAmount,
      commission: monthData.TotalCommissionAmount,
      promotion: monthData.TotalPromotionAmount
    }))
  }

  public async getCommissionData(params: any): Promise<any> {
    const result = [
      {
        month: 1,
        commission: 1882000
      },
      {
        month: 2,
        commission: 1809000
      },
      {
        month: 3,
        commission: 1322000
      },
      {
        month: 4,
        commission: 1122000
      },
      {
        month: 5,
        commission: 1114000
      },
      {
        month: 6,
        commission: 984000
      },
      {
        month: 7,
        commission: 711000
      },
      {
        month: 8,
        commission: 665000
      },
      {
        month: 9,
        commission: 580000
      },
      {
        month: 10,
        commission: 443000
      },
      {
        month: 11,
        commission: 441000
      },
      {
        month: 12,
        commission: 3025000
      }
    ]
    await sleep(1000)
    return this.mapMonthToString(result)
  }

  public async getUserData(params: any): Promise<any> {
    params = this.proccessFromToParams(params)
    const res = await http.get('api/services/app/StatisticAppServices/GetNewUserByMonth', { params })
    return this.mapMonthToString(res.data.result, (monthData) => ({
      partner: monthData.TotalPartnerUser,
      customer: monthData.TotalCustomerUser
    }))
  }

  public async getTotalRemovalRequestData(params: any): Promise<any> {
    params = this.proccessFromToParams(params)
    const res = await http.get('api/services/app/StatisticAppServices/GetRemovalRequestByMonth', { params })
    return this.mapMonthToString(res.data.result, (monthData) => ({
      request: monthData.TotalRequest,
      trip: monthData.TotalRequestDetail
    }))
  }

  public async getPromotionData(params: any) {
    params = this.proccessFromToParams(params)
    const res = await http.get('api/services/app/StatisticAppServices/GetPromotionBudgetByMonth', { params })
    return this.mapMonthToString(res.data.result, (monthData) => ({
      month: monthData.MonthNumber,
      promotionCount: monthData.PromotionCount,
      usedCount: monthData.TotalDiscountCount,
      totalBudget: monthData.MaxBudgetAmount,
      used: monthData.TotalDiscountUsedAmount
    }))
  }

  public async getRemovalRequestByFee(params: any) {
    params = this.proccessFromToParams(params)
    const res = await http.get('api/services/app/StatisticAppServices/GetServiceFeeByRequest', { params })
    const result = (res.data?.result || [])
      .filter((item) => item.TotalCount > 0)
      .map((item) => ({
        name: item.Name || 'Phụ phí', // The last item with empty name is Additional Service charge
        value: item.TotalCount
      }))
    return result
  }

  public async getAveragePrice(params: any) {
    params = this.proccessFromToParams(params)
    const res = await http.get('api/services/app/StatisticAppServices/GetAvgAmountOfRequest', { params })
    const data = res.data?.result || {}
    const result = [
      {
        title: 'WEEK',
        value: data?.AvgWeekAmount || 0
      },
      {
        title: 'MONTH',
        value: data?.AvgMonthAmount || 0
      },
      {
        title: 'YEAR',
        value: data?.AvgYearAmount || 0
      }
    ]

    return result
  }

  public async getTotalRemovalRequestByCancelledReason(params: any) {
    params = this.proccessFromToParams(params)
    const res = await http.get('api/services/app/StatisticAppServices/GetGroupRequestByCancelReason', { params })
    const result = (res.data?.result || []).map((item) => ({ ...item, Name: item.Name || L('OTHER') }))
    return result
  }

  public async getRemovalRequestByStatus(params: any) {
    params = this.proccessFromToParams(params)
    const res = await http.get('api/services/app/StatisticAppServices/GetGroupRequestByStatus', { params })
    const result = (res.data?.result || [])
      .filter((item) => item.TotalCount > 0)
      .map((item) => ({
        name: item.StatusName,
        value: item.TotalCount
      }))
    return result
  }

  public async getRemovalRequestByStatusAndTime(params: any) {
    params = this.proccessFromToParams(params)
    const res = await http.get('api/services/app/StatisticAppServices/GetGroupRequestByStatus', { params })
    const result = (res.data?.result || [])
      .filter((item) => item.TotalCount > 0)
      .map((item) => ({
        name: item.StatusName,
        value: item.TotalCount
      }))
    return result
  }

  public async getAcceptRateData(params: any) {
    params = this.proccessFromToParams(params)
    const res = await http.get('api/services/app/StatisticAppServices/GetRangeRateOfRequestByPartner', { params })
    const result = (res.data?.result || []).reduce((data, item) => {
      const existColumn = data.find((col) => col.title === item.RangeRate)
      if (existColumn) {
        existColumn[item.NAME === 'ACCEPTEDRATE' ? 'accepted' : 'cancelled'] = item.TotalCount
      } else {
        const newItem = {
          title: item.RangeRate,
          accepted: item.NAME === 'ACCEPTEDRATE' ? item.TotalCount : 0,
          cancelled: item.NAME === 'ACCEPTEDCANCELLED' ? item.TotalCount : 0
        }
        data.push(newItem)
      }
      return data
    }, [])

    return result
  }

  public async getParterAcceptRates(params: any) {
    params = this.proccessFromToParams(params)
    const res = await http.get('api/services/app/StatisticAppServices/GetAllRequestRateByPartner', { params })
    const result = res.data?.result || {}
    result.items = (result.items || []).map((item) => ({ ...item, id: uuid() }))
    return res.data?.result
  }

  public async exportRequestRateByPartner(params: any) {
    params = this.proccessFromToParams(params)
    const res = await http.get('api/Export/ExportRequestRateByPartner', { params, responseType: 'blob' })
    downloadFile(res.data, 'request-accepted-rate-by-partner.xlsx')
  }

  public async getPartnerCompletedRate(params: any) {
    params = this.proccessFromToParams(params)
    const res = await http.get('api/services/app/StatisticAppServices/GetAllRequestRateCompletedByPartner', { params })
    const result = res.data?.result || {}
    result.items = (result.items || []).map((item) => ({
      ...item,
      totalCompletedRate: (item.TotalCompleted / (item.TotalAssigned || 1)) * 100,
      id: uuid()
    }))
    return res.data?.result
  }

  public async exportPartnerCompletedRate(params) {
    params = this.proccessFromToParams(params)
    const res = await http.get('api/Export/ExportRequestRateCompletedByPartner', { params, responseType: 'blob' })
    downloadFile(res.data, 'request-completed-rate-by-partner.xlsx')
  }

  public async getRemovalRequestChangeByStaff(params: any) {
    params = this.proccessFromToParams(params)
    const res = await http.get('api/services/app/StatisticAppServices/GetRequestUpdateByAdmin', { params })
    const result = this.mapMonthToString(res.data.result, (item) => ({
      month: item.MonthNumber,
      requestCompleted: item.TotalCompletedCount,
      requestCancelled: item.TotalCancelledCount
    }))
    return result
  }

  public async getRemovalRequestChangeToConfirmWaiting(params: any) {
    params = this.proccessFromToParams(params)
    const res = await http.get('api/services/app/StatisticAppServices/GetRequestToWaitByMonth', { params })
    const result = this.mapMonthToString(res.data.result, (item) => ({
      month: item.MonthNumber,
      requestCount: item.TotalCount
    }))
    return result
  }

  public async getRemovalRequestByLocation(params: any): Promise<any> {
    params = this.proccessFromToParams(params)
    const res = await http.get('api/services/app/StatisticAppServices/GetStatisticByLocation', { params })

    const result = (res.data?.result || [])
      .filter((item) => item.TotalRequest > 0)
      .map((item) => ({
        area: item.DistrictName,
        totalRequest: item.TotalRequest,
        requestFound: item.TotalRequestFound,
        requestFoundAndFinish: item.TotalRequestFound - item.TotalRequestFoundAndCancelled,
        requestNotFound: item.TotalRequestNotFound,
        requestCancelled: item.TotalRequestCancelled, // Cancelled
        requestFoundButCancelled: item.TotalRequestFoundAndCancelled,
        totalTrip: item.TotalRequestDetail
      }))
    return result
  }

  public async getTopAreaMostRequest(params: any): Promise<any> {
    const result = [
      {
        area: 'Quận 1',
        request: 982,
        completed: 780
      },
      {
        area: 'Quận 2',
        request: 109,
        completed: 89
      },
      {
        area: 'Quận 4',
        request: 322,
        completed: 230
      },
      {
        area: 'Quận 3',
        request: 912,
        completed: 814
      },
      {
        area: 'Quận Bình Tân',
        request: 1214,
        completed: 1080
      },
      {
        area: 'Quận Tân Phú',
        request: 804,
        completed: 680
      },
      {
        area: 'Quận Tân Bình',
        request: 711,
        completed: 578
      },
      {
        area: 'Quận 9',
        request: 165,
        completed: 81
      },
      {
        area: 'Quận Thủ Đức',
        request: 820,
        completed: 799
      },
      {
        area: 'Huyện Nhà Bè',
        request: 143,
        completed: 80
      }
    ]
    await sleep(1000)
    return result
  }

  public async getAverangeRating(params: any): Promise<any> {
    params = this.proccessFromToParams(params)
    const res = await http.get('api/services/app/StatisticAppServices/GetRatingRequestOfCustomer', { params })

    const result = (res.data?.result || []).map((item) => ({
      name: L('RATING_{0}', item.Number),
      value: item.TotalCount
    }))
    return result
  }

  public async getAverangeRequestPerPartner(params: any): Promise<any> {
    const result = [
      {
        title: '0% - 25%',
        accept: 92,
        cancel: 8
      },
      {
        title: '25% - 50%',
        accept: 88,
        cancel: 12
      },
      {
        title: '50% - 75%',
        accept: 77,
        cancel: 23
      },
      {
        title: '75% - 100%',
        accept: 68,
        cancel: 32
      }
    ]
    await sleep(1000)
    return result
  }

  private proccessFromToParams(params) {
    const filters = { ...(params || {}) }

    const [fromDate, toDate] = params.dateFromTo || []
    filters.fromDate = fromDate ? dayjs(fromDate).startOf('day').toJSON() : dayjs().startOf('year').toJSON()
    filters.toDate = toDate ? dayjs(toDate).endOf('day').toJSON() : dayjs().endOf('year').toJSON()
    delete filters.dateFromTo

    return filters
  }

  private mapMonthToString = (data: any[], cbMapChartFields?) => {
    const translateMonth = (month: number) => {
      switch (month) {
        case 1:
          return L('CALENDAR_JAN')
        case 2:
          return L('CALENDAR_FEB')
        case 3:
          return L('CALENDAR_MAR')
        case 4:
          return L('CALENDAR_APR')
        case 5:
          return L('CALENDAR_MAY')
        case 6:
          return L('CALENDAR_JUN')
        case 7:
          return L('CALENDAR_JUL')
        case 8:
          return L('CALENDAR_AUG')
        case 9:
          return L('CALENDAR_SEP')
        case 10:
          return L('CALENDAR_OCT')
        case 11:
          return L('CALENDAR_NOV')
        case 12:
          return L('CALENDAR_DEC')
        default:
          return ''
      }
    }

    const returnValues = data.map((item) => ({
      ...(cbMapChartFields ? cbMapChartFields(item) : {}),
      month: translateMonth(item.MonthNumber)
    }))
    return returnValues
  }
}
export default new DashboardService()
